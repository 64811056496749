import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {RequestService} from "../../../service/request.service";


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    fullName: new FormControl(null),
    subject: new FormControl(null),
    phone: new FormControl(null),
    email: new FormControl(null),
    messageTxt: new FormControl(null),
    recaptcha: new FormControl(null),
  });

  submitted = false;
  token: string|undefined;

  constructor(
    private requestService: RequestService,
  ) {
    this.token = undefined;
  }


  ngOnInit(): void {
    this.contactForm.controls['fullName'].setValidators(Validators.required);
    this.contactForm.controls['subject'].setValidators(Validators.required);
    this.contactForm.controls['phone'].setValidators(Validators.required);
    this.contactForm.controls['email'].setValidators(Validators.required);
    this.contactForm.controls['messageTxt'].setValidators(Validators.required);
    this.contactForm.controls['recaptcha'].setValidators(Validators.required);
  }

  recaptchaResolved(response: string) {
    console.log('Resolved captcha with reponse: "', response, '"');
  }

  recaptchaErrored()  {
    console.warn('reCAPTCHA error encountered.');
  }

  submitContactForm() {
    console.log('contactForm is valid: ', this.contactForm.valid);
    console.log('this.contactForm.value', this.contactForm.value);
    if (this.contactForm.valid) {
         for (const control of Object.keys(this.contactForm.controls)) {
           this.contactForm.controls[control].markAsTouched();
         }
        this.requestService.sendEmail(this.contactForm.value);
        this.submitted = true;
      }
  }
}
