// import {LocalSegmentRegexp, LocalUsRegexp, InternationalUSRegexp} from '@helpers/regex.helper';

export class PhoneNumberUtil {


  /**
   * Use this Regexp for check
   * US local phone number without formatting
   */
  LocalUsRegexp = /\d{10}/gm;

  /**
   * Use this Regexp for check
   * US international phone number without formatting
   */
  InternationalUSRegexp = /^1[\d]{10}/gm;

  /**
   * Use this Regexp for slice phone
   * to segment
   */
  LocalSegmentRegexp = /^(\d{3})(\d{3})(\d{4})$/;

  isValidPhoneNumber(phoneNumber: string): boolean {

    if (this.parsePhoneNumber(phoneNumber)) {
      return true;
    } else {
      return false;
    }
  }

  parsePhoneNumber(phoneNumber: string): string[] {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    const internationalPhone = new RegExp(this.InternationalUSRegexp).test(cleaned);
    if (internationalPhone) {
      return cleaned.slice(1).match(this.LocalSegmentRegexp);
    }

    const localPhone = new RegExp(this.LocalUsRegexp).test(cleaned);
    if (localPhone) {
      return cleaned.match(this.LocalSegmentRegexp);
    }

    return null;
  }

  toUSPhoneFormat(phoneNumber: string): string {
    const parse = this.parsePhoneNumber(phoneNumber);

    if (parse) {
      return '(' + parse[1] + ') ' + parse[2] + '-' + parse[3];
    }
    ;

    return phoneNumber;
  }
}
