import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ListingService} from '../../../service/listing.service';

@Component({
  selector: 'app-listing-detail-one',
  templateUrl: './listing-detail-one.component.html',
  styleUrls: ['./listing-detail-one.component.css']
})
export class ListingDetailOneComponent implements OnInit {

  public property: any;
  public listing: any;
  public areas: any;

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    fade: true,
    prevArrow: '.slider-prev',
    nextArrow: '.slider-next',
  };

  public middleSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    prevArrow: '.slider-prev1',
    nextArrow: '.slider-next1',
    lazyLoad: 'progressive',
  };

  constructor(private route: ActivatedRoute,
              private listingService: ListingService) {
  }

  ngOnInit(): void {
    this.loadListing();
  }

  async loadListing() {
    this.route.params.subscribe(async (params) => {
      await this.listingService.getListing(params.id).then((response: any) => {
        this.property = response.property;
        this.areas = response.areas;

        this.listing = (this.property.listings)
          .filter(e => e.listing_contract_type_cd === 'SALE')
          .filter(e => (e.listing_status_cd).includes('AVAILABLE', 'UNDER_CONTRACT'))[0];
      });
    });
  }

}
