import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
// @ts-ignore
import {ListingListInterface} from '../arces/pages/interface/listing-list.interface';
import {environment} from '../../environments/environment';

@Injectable()
export class ListingService {

  protected MV_LISTING_ENDPOINT = environment.apiListingUrl;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get One Listing by ListingId
   */
  getListing(listingId: number): Promise<object> {
    const url = `${this.MV_LISTING_ENDPOINT}/property/from-id?propertyId=${listingId}&info=1`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((response: any) => {
        resolve(response);
      });
    });
  }

  /**
   * Get Featured listings, most are owned/manage by MV Realty
   */
  getListings(listing: ListingListInterface): Promise<object> {
    const url = `${this.MV_LISTING_ENDPOINT}/properties/${listing.area}?listingContractType=SALE
    &limit=${listing.limit}&images=${listing.images}&page=${listing.page}
    &priceMin=${listing.minPrice}&priceMax=${listing.maxPrice}&bedsMin=${listing.minNumBeds}
    &bathsMin=${listing.minNumBaths}&orderBy=${listing.orderBy}&sortDir=${listing.sortDir}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        resolve(list);
      });
    });
  }

}
