<!-- Banner Start -->
<div class="banner banner-1 banner-3 dark-overlay bg-cover bg-center"
     style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="banner-item">
      <div class="banner-inner">
        <div class="banner-text">
          <div class="container" style="padding: 0">
            <div class="row">
              <div class="col-12">
                <h1 class="title text-white">The Smart Move</h1>
                <p class="subtitle text-white">Whether you’re buying, selling, or joining our innovative Homeowner Benefit Program ®, it's the Smart Move.</p>
              </div>
              <div class="col-sm-12 col-lg-6">
                <a class="btn-custom primary btn-lg btn-block" routerLink='/contact'>Get Started</a>
                <a class="btn-custom primary btn-lg btn-block" href="https://homeownerbenefit.com/">About the Homeowner Benefit Program ®</a>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="acr-filter-form">-->
<!--          <form action="./listing-grid">-->
<!--            <div class="row">-->
<!--              <div class="col-lg-3 col-md-6">-->
<!--                <div class="form-group acr-custom-select">-->
<!--                  <label for="location">Location: </label>-->
<!--                  <select class="acr-select2" name="location" id="location">-->
<!--                    <option value="*">Any Location</option>-->
<!--                    <option [value]="location" *ngFor="let location of searchableLocations">{{location}}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-3 col-md-6">-->
<!--                <div class="acr-custom-select form-group">-->
<!--                  <label for="type">Type: </label>-->
<!--                  <select class="acr-select2" name="type" id="type">-->
<!--                    <option selected value="*">Any Type</option>-->
<!--                    <option value="House">House</option>-->
<!--                    <option value="Apartment">Apartment</option>-->
<!--                    <option value="Condo">Condo</option>-->
<!--                    <option value="Townhome">Townhome</option>-->
<!--                    <option value="Villa">Villa</option>-->
<!--                    <option value="Duplex">Duplex</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4 col-md-6">-->
<!--                <div class="form-group acr-custom-select">-->
<!--                  <label>Price Range: </label>-->
<!--                  <select class="acr-select2" name="price">-->
<!--                    <option selected value="*">Any Range</option>-->
<!--                    <option value="$100k - $120k">Less than $120k</option>-->
<!--                    <option value="$120k - $140k">$150k - $250k</option>-->
<!--                    <option value="$140k - $160k">$250k - $350k</option>-->
<!--                    <option value="$160k - $180k">$350k - $450k</option>-->
<!--                    <option value="$180k - $200k">More than $450k</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="submit-btn col-lg-2 col-md-6">-->
<!--                <div class="form-group">-->
<!--                  <button type="submit" class="btn-custom secondary btn-block">Search listings</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="advanced-search">-->
<!--              <div class="row">-->
<!--                <div class="col-lg-3 col-md-6">-->
<!--                  <div class="acr-custom-select form-group">-->
<!--                    <label for="beds">Beds: </label>-->
<!--                    <select class="acr-select2" name="beds" id="beds">-->
<!--                      <option selected value="*">Any amount</option>-->
<!--                      <option value="1">1</option>-->
<!--                      <option value="2">2</option>-->
<!--                      <option value="3">3</option>-->
<!--                      <option value="4">4</option>-->
<!--                      <option value="5+">5+</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 col-md-6">-->
<!--                  <div class="acr-custom-select form-group">-->
<!--                    <label for="baths">Bathrooms: </label>-->
<!--                    <select class="acr-select2" name="baths" id="baths">-->
<!--                      <option selected value="*">Any amount</option>-->
<!--                      <option value="1">1</option>-->
<!--                      <option value="2">2</option>-->
<!--                      <option value="3">3</option>-->
<!--                      <option value="4">4</option>-->
<!--                      <option value="5+">5+</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 col-md-6">-->
<!--                  <div class="acr-custom-select form-group">-->
<!--                    <label for="area_size">Area: </label>-->
<!--                    <select class="acr-select2" name="area_size" id="area_size">-->
<!--                      <option selected value="*">Any size</option>-->
<!--                      <option value="House">Less than 700 sqft.</option>-->
<!--                      <option value="Apartment">700 sqft. - 1000 sqft.</option>-->
<!--                      <option value="Condo">1000 sqft. - 1300 sqft.</option>-->
<!--                      <option value="Townhome">1300 sqft. - 1600 sqft.</option>-->
<!--                      <option value="Villa">1600 sqft. - 1900 sqft.</option>-->
<!--                      <option value="Duplex">More than 1900 sqft.</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-3 col-md-6">-->
<!--                  <div class="acr-custom-select form-group">-->
<!--                    <label for="range">Range: </label>-->
<!--                    <select class="acr-select2" name="range" id="range">-->
<!--                      <option selected value="*">Any Range</option>-->
<!--                      <option value="Within 2 miles">Within 2 miles</option>-->
<!--                      <option value="Within 4 miles">Within 4 miles</option>-->
<!--                      <option value="Within 6 miles">Within 6 miles</option>-->
<!--                      <option value="Within 8 miles">Within 8 miles</option>-->
<!--                      <option value="Within 10 miles">Within 10 miles</option>-->
<!--                      <option value="Within 12 miles">Within 12 miles</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
<!--          <div class="advanced-search-trigger semi-circle">-->
<!--            <i class="fas fa-chevron-down"></i>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>
<!-- Banner End -->
