<!-- Header Start -->
<app-header-four></app-header-four>
<!-- Header Start -->
<!-- Banner Start -->
<app-principal-banner-component></app-principal-banner-component>
<!-- Banner End -->
<!-- Top Listings Start -->
<!--<app-top-listings-component></app-top-listings-component>-->
<!-- Top Listings End -->
<!-- Listings Slider Start -->
<!--<app-recent-listings-component></app-recent-listings-component>-->
<!-- Listings Slider End -->
<!-- Agents Start -->
<app-our-offices-component></app-our-offices-component>
<!-- Agents End-->

<!-- CTA Start -->
<div class="section bg-light infographics-2 bg-norepeat bg-bottom"
     style="background-image: url(assets/img/misc/bldg.png)">
  <div class="container">
    <div class="cta cta-3">
      <div class="row">
        <div class="col-lg-6 mb-lg-30">
          <div class="section-title-wrap">
            <h5 class="custom-primary">Find a Home</h5>
            <h2 class="title">Why MV Realty</h2>
            <p class="subtitle">Our agents treat you with the utmost of care while utilizing cutting edge real estate
              technology to help locate your next dream home.
            </p>

            <img src="/assets/img/why_mv_front.jpeg" alt="MV Real estate"/>
            <!--            <a routerLink='/listing-map' class="btn-custom">Browse Listings</a>-->
          </div>
        </div>
        <div class="col-lg-6 infographics-4">
          <div class="row">
            <div class="col-md-6">
              <div class="acr-infographic-item">
                <i class="flaticon-house"></i>
                <div class="acr-infographic-item-body">
                  <h5>Dedication</h5>
                  <p>We pride ourselves in always being available to meet our clients' every need, now and in the
                    future.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="acr-infographic-item">
                <i class="flaticon-sold"></i>
                <div class="acr-infographic-item-body">
                  <h5>Experience</h5>
                  <p>Our team focused approach is led by expert Brokers with the knowledge and experience to best serve
                    you.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="acr-infographic-item">
                <i class="flaticon-writing"></i>
                <div class="acr-infographic-item-body">
                  <h5>Empowerment</h5>
                  <p>We utilize the latest technology to empower our clients with the most up-to-date market data and
                    analysis.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="acr-infographic-item">
                <i class="flaticon-company"></i>
                <div class="acr-infographic-item-body">
                  <h5>Community</h5>
                  <p>Our diverse team of agents from around the country offer local expertise to serve your
                    community.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- CTA End -->

<!-- Single Agent Start -->
<app-agent-of-the-month-component></app-agent-of-the-month-component>
<!-- Single Agent End -->

<div class="section light-bg infographics-2 bg-norepeat bg-bottom" style="background-image: url(assets/img/misc/bldg.png);">
  <div class="container">
    <div class="col-12" style="height: 120px"></div>
  </div>
</div>

<app-footer></app-footer>
