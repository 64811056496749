<app-header-five></app-header-five>
<div class="acr-auth-container">
    <div class="acr-auth-content">
        <form method="post">
            <div class="auth-text"> 
                <h3>Create An Acres Account</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
            <div class="form-group">
                <label>Username</label>
                <input type="text" class="form-control form-control-light" placeholder="Username" name="username" value="">
            </div>
            <div class="form-group">
                <label>Email Address</label>
                <input type="email" class="form-control form-control-light" placeholder="Email Address" name="email" value="">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control form-control-light" placeholder="Password" name="password" value="">
            </div>
            <button type="submit" class="btn-custom secondary btn-block">Register</button>
            <div class="auth-seperator">
                <span>OR</span>
            </div>
            <div class="social-login">
                <button type="button" class="acr-social-login facebook"><i class="fab fa-facebook-f"></i> Continue with Facebook </button>
                <button type="button" class="acr-social-login google"><i class="fab fa-google"></i> Continue with Google</button>
            </div>
            <p class="text-center mb-0">Already have an account? <a routerLink='/login'>Login</a> </p>
        </form>
    </div>
    <div class="acr-auth-bg">
        <div class="acr-auth-bg-slider acr-cs-bg-slider">
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/2.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/3.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/1.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
        </div>
    </div>
</div>