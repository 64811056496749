<div class="sidebar sidebar-left">
    <div class="sidebar-widget">
        <div class="acr-collapse-trigger acr-custom-chevron-wrapper">
            <h5>Filter Listings</h5>
            <div class="acr-custom-chevron">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="acr-collapsable">
            <div class="acr-filter-form">
                <form method="post">
                    <div class="acr-custom-select form-group">
                        <label>Location: </label>
                        <select class="acr-select2" name="location">
                            <option value="" label="Location"></option>
                            <option value="*">Any Location</option>
                            <option value="California, CA">California, CA</option>
                            <option value="Lawndale, CA">Lawndale, CA</option>
                            <option value="Stroudsburg, PA">Stroudsburg, PA</option>
                            <option selected value="West Roxbury, MA">West Roxbury, MA</option>
                            <option value="Ponte Vedra Beach, FL">Ponte Vedra Beach, FL</option>
                            <option value="Fort Worth, TX">Fort Worth, TX</option>
                            <option value="Willingboro, NJ">Willingboro, NJ</option>
                        </select>
                    </div>
                    <div class="acr-custom-select form-group">
                        <label>Status: </label>
                        <select class="acr-select2" name="status">
                            <option value="" label="Status"></option>
                            <option selected value="*">Any Status</option>
                            <option value="For Rent">For Rent</option>
                            <option value="Featured">Featured</option>
                            <option value="For Sale">For Sale</option>
                            <option value="Leased">Leased</option>
                            <option value="New Addition">New Addition</option>
                            <option value="Sold">Sold</option>
                            <option value="Rental">Rental</option>
                            <option value="Reduced">Reduced</option>
                            <option value="Special Offer">Special Offer</option>
                        </select>
                    </div>
                    <div class="acr-custom-select form-group">
                        <label>Price Range: </label>
                        <select class="acr-select2" name="price">
                            <option value="" label="Price Range"></option>
                            <option selected value="*">Any Range</option>
                            <option value="$60k - $80k">$60k - $80k</option>
                            <option value="$80k - $100k">$80k - $100k</option>
                            <option value="$100k - $120k">$100k - $120k</option>
                            <option value="$120k - $140k">$120k - $140k</option>
                            <option value="$140k - $160k">$140k - $160k</option>
                            <option value="$160k - $180k">$160k - $180k</option>
                            <option value="$180k - $200k">$180k - $200k</option>
                        </select>
                    </div>
                    <div class="acr-custom-select form-group">
                        <label>Beds: </label>
                        <select class="acr-select2" name="beds">
                            <option value="" label="Beds"></option>
                            <option selected value="*">Any amount</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5+">5+</option>
                        </select>
                    </div>
                    <div class="acr-custom-select form-group">
                        <label>Bathrooms: </label>
                        <select class="acr-select2" name="baths">
                            <option value="" label="Bathrooms"></option>
                            <option selected value="*">Any amount</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5+">5+</option>
                        </select>
                    </div>
                    <div class="acr-custom-select form-group">
                        <label>Type: </label>
                        <select class="acr-select2" name="type">
                            <option value="" label="Type"></option>
                            <option selected value="*">Any Type</option>
                            <option value="House">House</option>
                            <option value="Apartment">Apartment</option>
                            <option value="Condo">Condo</option>
                            <option value="Townhome">Townhome</option>
                            <option value="Villa">Villa</option>
                            <option value="Duplex">Duplex</option>
                        </select>
                    </div>
                    <button type="submit" class="btn-block btn-custom" name="button">Apply filters</button>
                </form>
            </div>
        </div>
    </div>
    <div class="sidebar-widget">
        <div class="acr-collapse-trigger acr-custom-chevron-wrapper">
            <h5>Recent Listing</h5>
            <div class="acr-custom-chevron">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="acr-collapsable">
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                    <span class="listing-price">3,500$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                    <span class="listing-price">1,300$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                    <span class="listing-price">2,500$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                    <span class="listing-price">1,900$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
        </div>
    </div>
    <div class="sidebar-widget">
        <div class="acr-collapse-trigger acr-custom-chevron-wrapper">
            <h5>Mortgage Calculator</h5>
            <div class="acr-custom-chevron">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="acr-collapsable">
            <form method="post">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Total Amount" name="total" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Down Payment" name="down-p" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Interest Rate" name="interest" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Loan Term" name="loan-term" value="">
                </div>
                <div class="form-group">
                    <select class="form-control" name="cycle">
                        <option value="Daily">Daily</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                    </select>
                </div>
                <button type="submit" class="btn-custom btn-block" name="button">Calculate</button>
            </form>
        </div>
    </div>
</div>