<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Blog Grid</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog Grid</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Blog Posts Start -->
<div class="section posts">
    <div class="container">
        <div class="row">
            <!-- Posts Start -->
            <div class="col-lg-8">
                <div class="row masonry">
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/1.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/2.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Heather Mclayn</a> </p>
                                        <span class="post-date">March 3, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>What agencies are looking for in an agent</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>Comments by: </span>
                                        <div class="post-comment-authors-img">
                                            <a href="#"> <img src="assets/img/people/1.jpg" alt="comment author"> </a>
                                            <a href="#"> <img src="assets/img/people/5.jpg" alt="comment author"> </a>
                                            <a href="#"> +7 </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/2.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/2.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Heather Mclayn</a> </p>
                                        <span class="post-date">March 3, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>Changing the way we sell and buy real estate</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>0 Comments</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/3.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/1.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Randy Blue</a> </p>
                                        <span class="post-date">March 13, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>Families, its the best time to start buying</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>Comments by: </span>
                                        <div class="post-comment-authors-img">
                                            <a href="#"> <img src="assets/img/people/3.jpg" alt="comment author"> </a>
                                            <a href="#"> <img src="assets/img/people/4.jpg" alt="comment author"> </a>
                                            <a href="#"> +3 </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/4.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/2.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Heather Mclayn</a> </p>
                                        <span class="post-date">March 3, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>Our all new duplex apartment is up for grabs</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>0 Comments</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/5.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/1.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Randy Blue</a> </p>
                                        <span class="post-date">March 3, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>Villa sales are going down this year, find out why</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>0 Comments </span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                    <!-- Post Start -->
                    <div class="col-md-6 masonry-item">
                        <article class="post">
                            <div class="post-thumbnail">
                                <a routerLink='/blog-single'><img src="assets/img/blog/6.jpg" alt="blog post"></a>
                            </div>
                            <div class="post-body">
                                <div class="post-author">
                                    <img src="assets/img/people/2.jpg" alt="author">
                                    <div class="post-author-body">
                                        <p> <a href="#">Heather Mclayn</a> </p>
                                        <span class="post-date">March 3, 2020</span>
                                    </div>
                                </div>
                                <h5 class="post-title"> <a routerLink='/blog-single'>Flats in wisconsin are being held for rental</a> </h5>
                                <p class="post-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>
                                <div class="post-controls">
                                    <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                    <div class="post-comment-authors">
                                        <span>Comments by: </span>
                                        <div class="post-comment-authors-img">
                                            <a href="#"> <img src="assets/img/people/3.jpg" alt="comment author"> </a>
                                            <a href="#"> <img src="assets/img/people/4.jpg" alt="comment author"> </a>
                                            <a href="#"> +3 </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <!-- Post End -->
                </div>
                <!-- Pagination Start -->
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-left"></i> </a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active">
                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-right"></i> </a></li>
                </ul>
                <!-- Pagination End -->
            </div>
            <!-- Posts End -->
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Blog Posts End -->
<app-footer></app-footer>