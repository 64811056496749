import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-listing-sidebar',
  templateUrl: './listing-sidebar.component.html',
  styleUrls: ['./listing-sidebar.component.css']
})
export class ListingSidebarComponent implements OnInit {

  public params;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.params = params;
        }
      );
  }

}
