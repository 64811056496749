import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorComponent} from './arces/pages/error/error.component';
import {AboutComponent} from './arces/pages/about/about.component';
import {AgencyComponent} from './arces/pages/agency/agency.component';
// import {AgencyDetailComponent} from './arces/pages/agency-detail/agency-detail.component';
import {AgentComponent} from './arces/pages/agent/agent.component';
// import {AgentDetailComponent} from './arces/pages/agent-detail/agent-detail.component';
import {BlogGridComponent} from './arces/pages/blog-grid/blog-grid.component';
import {BlogListComponent} from './arces/pages/blog-list/blog-list.component';
// import {BlogSingleComponent} from './arces/pages/blog-single/blog-single.component';
// import {ComingSoonComponent} from './arces/pages/coming-soon/coming-soon.component';
import {CompareListingComponent} from './arces/pages/compare-listing/compare-listing.component';
import {ContactComponent} from './arces/pages/contact/contact.component';
import {FaqComponent} from './arces/pages/faq/faq.component';
import {HomeComponent} from './arces/pages/home/home.component';
import {HomeTwoComponent} from './arces/pages/home-two/home-two.component';
import {HomeThreeComponent} from './arces/pages/home-three/home-three.component';
import {HomeFourComponent} from './arces/pages/home-four/home-four.component';
import {HomeFiveComponent} from './arces/pages/home-five/home-five.component';
import {LegalComponent} from './arces/pages/legal/legal.component';
import {ListingDetailOneComponent} from './arces/pages/listing-detail-one/listing-detail-one.component';
import {ListingDetailTwoComponent} from './arces/pages/listing-detail-two/listing-detail-two.component';
import {ListingDetailThreeComponent} from './arces/pages/listing-detail-three/listing-detail-three.component';
import {ListingGridComponent} from './arces/pages/listing-grid/listing-grid.component';
import {ListingListComponent} from './arces/pages/listing-list/listing-list.component';
import {ListingMapComponent} from './arces/pages/listing-map/listing-map.component';
import {LoginComponent} from './arces/pages/login/login.component';
import {PricingComponent} from './arces/pages/pricing/pricing.component';
import {ProfileComponent} from './arces/pages/profile/profile.component';
import {ProfileListingComponent} from './arces/pages/profile-listing/profile-listing.component';
import {ProfileSavedListingComponent} from './arces/pages/profile-saved-listing/profile-saved-listing.component';
import {RegisterComponent} from './arces/pages/register/register.component';
import {ServicesComponent} from './arces/pages/services/services.component';
import {SubmitListingComponent} from './arces/pages/submit-listing/submit-listing.component';
import {HeaderComponent} from './arces/layouts/header/header.component';
import {HeaderTwoComponent} from './arces/layouts/header-two/header-two.component';
import {HeaderThreeComponent} from './arces/layouts/header-three/header-three.component';
import {HeaderFourComponent} from './arces/layouts/header-four/header-four.component';
import {FooterComponent} from './arces/layouts/footer/footer.component';
import {FooterTwoComponent} from './arces/layouts/footer-two/footer-two.component';
import {FooterThreeComponent} from './arces/layouts/footer-three/footer-three.component';
import {BlogSidebarComponent} from './arces/layouts/blog-sidebar/blog-sidebar.component';
import {ListingSidebarComponent} from './arces/layouts/listing-sidebar/listing-sidebar.component';
import {PreloaderComponent} from './arces/layouts/preloader/preloader.component';
import {HeaderFiveComponent} from './arces/layouts/header-five/header-five.component';
import {ListingService} from './service/listing.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {PrincipalBannerComponent} from './arces/pages/shared/banner/principal/principal-banner.component';
import {SecondaryBannerComponent} from './arces/pages/shared/banner/secondary/secondary-banner.component';
import {TopListingsComponent} from './arces/pages/shared/listings/top-listings/top-listings.component';
import {RecentListingsComponent} from './arces/pages/shared/listings/recent-listings/recent-listings.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {MortgagePipe} from './pipes/mortgage.pipe';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {TopListingComponent} from './arces/pages/shared/listings/top-listing/top-listing.component';
import {AgentOfTheMonthComponent} from './arces/pages/shared/agents/agent-of-the-month/agent-of-the-month.component';
import {ListingComponent} from './arces/pages/shared/listings/listing/listing.component';
import {AgentService} from './service/agent.service';
import {MeetOurAgentsComponent} from './arces/pages/shared/agents/meet-our-agents/meet-our-agents.component';
import {OurOfficesComponent} from './arces/pages/shared/offices/our-offices/our-offices.component';
import {BrokerageService} from './service/brokerage.service';
import {LocalPhoneNumberPipe} from './common/pipes/local-phone-number.pipe';
import {BrokerageDetailComponent} from './arces/pages/brokerage-detail/brokerage-detail.component';
import {BrokerageComponent} from './arces/pages/brokerage/brokerage.component';
import {TermsComponent} from './arces/pages/terms/terms.component';
import {PolicyComponent} from './arces/pages/policy/policy.component';
import {TestimonialsComponent} from './arces/pages/testimonials/testimonials.component';
import {BrokerageSingleComponent} from './arces/pages/shared/brokerage/single/brokerage-single.component';
import {ReactiveFormsModule} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import {RequestService} from './service/request.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {GoogleAnalyticsService} from './service/google-analytics.service';
import {CommonService} from './service/common.service';
import { AgentsComponent } from './arces/pages/agents/agents.component';
import { PressComponent } from './arces/pages/press/press.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    AboutComponent,
    AgencyComponent,
    // AgencyDetailComponent,
    AgentComponent,
    // AgentDetailComponent,
    BlogGridComponent,
    BlogListComponent,
    // BlogSingleComponent,
    // ComingSoonComponent,
    CompareListingComponent,
    ContactComponent,
    FaqComponent,
    HomeComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    HomeFourComponent,
    HomeFiveComponent,
    LegalComponent,
    ListingDetailOneComponent,
    ListingDetailTwoComponent,
    ListingDetailThreeComponent,
    ListingGridComponent,
    ListingListComponent,
    ListingMapComponent,
    LoginComponent,
    PricingComponent,
    ProfileComponent,
    ProfileListingComponent,
    ProfileSavedListingComponent,
    RegisterComponent,
    ServicesComponent,
    SubmitListingComponent,
    HeaderComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFourComponent,
    FooterComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    BlogSidebarComponent,
    ListingSidebarComponent,
    PreloaderComponent,
    HeaderFiveComponent,
    AgentsComponent,
    PressComponent,
    // Shared
    RecentListingsComponent,
    TopListingsComponent,
    ListingComponent,
    PrincipalBannerComponent,
    SecondaryBannerComponent,
    TopListingComponent,
    AgentOfTheMonthComponent,
    MeetOurAgentsComponent,
    OurOfficesComponent,
    BrokerageComponent,
    BrokerageDetailComponent,
    TermsComponent,
    PolicyComponent,
    TestimonialsComponent,
    BrokerageSingleComponent,
    // Pipes
    MortgagePipe,
    LocalPhoneNumberPipe,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxImageZoomModule,
        SlickCarouselModule,
        ReactiveFormsModule,
        FormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgbModule,
    ],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    ListingService,
    AgentService,
    BrokerageService,
    HttpClient,
    SlickCarouselModule,
    RequestService,
    GoogleAnalyticsService,
    CommonService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
