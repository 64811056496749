import {Component, Input, OnInit} from '@angular/core';
import {BrokerageService} from '../../../../../service/brokerage.service';
import {LocalPhoneNumberPipe} from '../../../../../common/pipes/local-phone-number.pipe';

@Component({
  selector: 'app-brokerage-single',
  templateUrl: './brokerage-single.component.html',
  styleUrls: ['./brokerage-single.component.css']
})
export class BrokerageSingleComponent implements OnInit {

  @Input() brokerage;

  constructor(protected brokerageService: BrokerageService) {
  }

  async ngOnInit() {
  }

  noImage(e) {
    e.src = 'assets/img/no-image.png';
  }

  stateName(state) {
    if (state == "CA") {
      return 'MV REALTY OF CALIFORNIA, INC';
    }
    else if (state == "ID") {
      return 'MV REALTY OF IDAHO, LLC';
    }
    else if (state == "NV") {
      return 'MV REALTY OF NEVADA LLC';
    }
  }

}
