import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestService} from '../../../service/request.service';
import {GoogleAnalyticsService} from '../../../service/google-analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  formData;
  submission = false;

  constructor(protected requestService: RequestService,
              protected googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    const emailFormatValidator = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    this.formData = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, emailFormatValidator]),
      phone: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      zip: new FormControl(null),
      message: new FormControl(null, [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  onSubmit() {
    const formData = this.formData.getRawValue();
    formData.subject = `Homesatmv Contact Form`;
    formData.recipients = ['david@homesatmv.com', 'amanda@homesatmv.com', 'sunny@homesatmv.com', 'stevenk@homesatmv.com'];
    this.requestService.sendEmail(formData).then((response: any) => {
      if (response.success) {
        this.submission = true;
        this.googleAnalyticsService.emitEvent('form', 'submit', 'contact', 1);
      } else {
        alert('Not possible to complete your request, try in a few minutes');
      }
      this.formData.reset();
    });
  }

}
