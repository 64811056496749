<!-- Brokerage Start -->
<div class="section" id="our-locations">
  <div class="container">
    <div class="section-title-wrap section-header flex-header">
      <div class="section-title-text">
        <h5 class="custom-primary">Our Locations</h5>
        <h2 class="title">Serving Your Community</h2>
      </div>
      <div class="acr-arrows primary-arrows">
        <i class="slider-prev-brokerage fas fa-arrow-left slick-arrow"></i>
        <i class="slider-next-brokerage fas fa-arrow-right slick-arrow"></i>
      </div>
    </div>

    <ngx-slick-carousel class="agents-slider"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let brokerage of brokerages" class="slide acr-agent">
        <app-brokerage-single [brokerage]="brokerage"></app-brokerage-single>
      </div>
    </ngx-slick-carousel>


  </div>
</div>
<!-- Brokerage End -->
