<app-header-four></app-header-four>
<!-- Subheader Start -->
<div class="header-1"></div>
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">Press</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink='/'> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Press</li>
        </ol>
      </nav>
    </div>
  </div>
</div>


<!-- Subheader End -->
<div class="section">
  <div class="container">
    <!-- Press Section Start -->
    <div class="row">
        <div class="column">
            <div class="card">
                <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
                <div class="container card-text">
                  <h4 class="tiny-text-kinda">Amanda Zachman of MV Realty: Five Things You Need To Know To Succeed In The Real Estate Industry</h4>
                </div>
                <div class="read-more">
                  <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
                  <p style="float: right;"><a href="https://medium.com/authority-magazine/amanda-zachman-of-mv-realty-five-things-you-need-to-know-to-succeed-in-the-real-estate-industry-b6411a8436c"> <a href=""> Read More </a> </a></p>
                </div>
            </div>
        </div>

      <div class="column">
          <div class="card">
              <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
              <div class="container card-text">
                <h4 class="tiny-text">MV Realty Selects Feintuch Communications as its PR Agency Partner</h4>
              </div>
              <div class="read-more">
                <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
                <p style="float: right;"><a href="https://www.tmcnet.com/usubmit/-mv-realty-selects-feintuch-communications-as-its-pr-/2022/08/29/9663472.htm"> <a href=""> Read More </a> </a></p>
              </div>
          </div>
      </div>

      <div class="column">
        <div class="card">
          <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
          <div class="container card-text">
            <h4 class="tiny-text">Revolving Door Agency Moves: BooneOakley, Duncan Channon, Togetherwith & More</h4>
          </div>
          <div class="read-more">
            <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
            <p style="float: right;"><a href="https://www.adweek.com/agencyspy/revolving-door-agency-moves-booneoakley-duncan-channon-togetherwith-more/178405/"> Read More </a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="column">
        <div class="card">
          <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
          <div class="container card-text">
            <h4 class="tiny-text">Tips to Sell Your Home In Today's Market</h4>
          </div>
          <div class="read-more">
            <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
            <p style="float: right;"><a href="https://www.mynewstouse.com/stories/tips-to-sell-your-home-in-todays-market,36666"> Read More </a></p>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
          <div class="container card-text">
            <h4>MV REALTY INTRODUCES RESIDENTIAL REAL ESTATE MONITORING SERVICE</h4>
          </div>
          <div class="read-more">
            <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
            <p style="float: right;"><a href="https://www.tmcnet.com/usubmit/-mv-realty-introduces-residential-real-estate-title-monitoring-/2022/09/20/9677290.htm"> Read More </a></p>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <img src="assets/img/press/quote-marks-press-white.svg" alt="Quote" style="width:20%; padding-top: 1em; padding-bottom: 1.5em">
          <div class="container card-text">
            <h4>This company pays quick cash to list your home. The catch? A 40-year contract</h4>
          </div>
          <div class="read-more">
            <img id="press-logo" src="assets/img/logo_sm.png" alt="Quote">
            <p style="float: right;"><a href="https://www.centredaily.com/news/business/article265401531.html"> Read More </a></p>
          </div>
        </div>
      </div>

    </div>
    <!-- Press Section End -->
  </div>
</div>

<app-footer></app-footer>
