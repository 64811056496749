<!-- Agents Start -->
<div class="section agents light-bg">
  <div class="container">
    <div class="section-title-wrap section-header flex-header">
      <div class="section-title-text">
        <h5 class="custom-primary">Our Locations</h5>
        <h2 class="title">Meet Our Agents</h2>
      </div>
      <div class="acr-arrows primary-arrows">
        <i class="slider-prev-agents fas fa-arrow-left slick-arrow"></i>
        <i class="slider-next-agents fas fa-arrow-right slick-arrow"></i>
      </div>
    </div>

    <ngx-slick-carousel class="agents-slider"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let agent of agents" class="slide acr-agent">
        <!-- Agent Start -->
          <div class="listing-badge featured">
            <i class="fas fa-star"></i>
          </div>
          <div class="acr-dots-wrapper acr-agent-thumb">
            <div class="acr-dots"></div>
            <a routerLink='/agent-detail'><img src="{{agent.profileImageLargeUrl}}" alt="{{agent.fullName}}"></a>
          </div>
          <div class="acr-agent-body">
            <h6><a routerLink='/agent-detail'>{{agent.fullName}}</a></h6>
            <span>Expert at MV Realty</span>
            <p>{{agent.agentBio}}</p>
            <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
          </div>
        <!-- Agent End -->
      </div>
    </ngx-slick-carousel>


  </div>
</div>
<!-- Agents End -->
