<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Submit Listing</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Listings</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Submit Listing</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row">
            <!-- Tabs Start -->
            <div class="col-md-4">
                <ul class="nav nav-tabs tab-cards" id="submitListingTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="basic-info-tab" data-toggle="tab" href="#basic-info" role="tab" aria-controls="basic-info" aria-selected="true"> <span>01</span> Basic Information</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="gallery-tab" data-toggle="tab" href="#gallery" role="tab" aria-controls="gallery" aria-selected="false"><span>02</span> Gallery</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="location-tab" data-toggle="tab" href="#location" role="tab" aria-controls="location" aria-selected="false"><span>03</span> Location</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="features-tab" data-toggle="tab" href="#features" role="tab" aria-controls="features" aria-selected="false"><span>04</span> Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false"><span>05</span> Details</a>
                    </li>
                </ul>
            </div>
            <!-- Tabs End -->
            <!-- Tab Content Start -->
            <div class="col-md-8">
                <form method="post">
                    <div class="tab-content m-0" id="submitListingTabContent">
                        <!-- Basic Information Start -->
                        <div class="tab-pane fade show active" id="basic-info" role="tabpanel" aria-labelledby="basic-info-tab">
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label>Property Description</label>
                                    <textarea name="content" rows="4" class="form-control" placeholder="Property Description"></textarea>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Property Name</label>
                                    <input type="text" class="form-control" placeholder="Property Name" name="name" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Property Status</label>
                                    <select class="form-control" name="status">
                                        <option value="For Rent">For Rent</option>
                                        <option value="Featured">Featured</option>
                                        <option value="For Sale">For Sale</option>
                                        <option value="Leased">Leased</option>
                                        <option value="New Addition">New Addition</option>
                                        <option value="Sold">Sold</option>
                                        <option value="Rental">Rental</option>
                                        <option value="Reduced">Reduced</option>
                                        <option value="Special Offer">Special Offer</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Property Type</label>
                                    <select class="form-control" name="type">
                                        <option value="House">House</option>
                                        <option value="Apartment">Apartment</option>
                                        <option value="Condo">Condo</option>
                                        <option value="Townhome">Townhome</option>
                                        <option value="Villa">Villa</option>
                                        <option value="Duplex">Duplex</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Property Price</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" class="form-control" name="price" placeholder="Property Price">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Rental Period</label>
                                    <select class="form-control" name="period">
                                        <option value="Monthly">Monthly</option>
                                        <option value="Yearly">Yearly</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Property Space (Sqft)</label>
                                    <input type="text" class="form-control" placeholder="Property Space (Sqft)" name="space" value="">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Property Video</label>
                                    <input type="text" class="form-control" placeholder="Property Video URL" name="video" value="">
                                </div>
                            </div>
                        </div>
                        <!-- Basic Information End -->
                        <!-- Gallery Start -->
                        <div class="tab-pane fade" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
                            <div class="form-group">
                                <label>Property Thumbnail</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="propertyThumbnail">
                                    <label class="custom-file-label" for="propertyThumbnail">Choose file</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Property Gallery</label>
                                <div id="acrListingGallery" class="dropzone">
                                    <div class="dropzone-msg dz-message needsclick">
                                        <i class="fas fa-cloud-upload-alt"></i>
                                        <h5 class="dropzone-msg-title">Drop files here or click to upload.</h5>
                                        <span class="dropzone-msg-desc">This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.</span>
                                    </div>
                                </div>
                                <span class="acr-form-notice">*You can upload up to 5 images for your listing</span>
                                <span class="acr-form-notice">*Listing images should be atleast 620x480 in dimensions</span>
                            </div>
                        </div>
                        <!-- Gallery End -->
                        <!-- Location Start -->
                        <div class="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                            <div class="form-group submit-listing-map">
                                <div id="map"></div>
                            </div>
                            <div class="form-group">
                                <span class="acr-form-notice">Drag and drop the marker to identify your <b>Latitude</b> and <b>Longitude</b> </span>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label>Full Address</label>
                                    <input type="text" name="address" class="form-control" placeholder="Full Address" value="">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Region</label>
                                    <select class="form-control" name="region">
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Washington DC">Washington DC</option>
                                        <option value="Los Angelas">Los Angelas</option>
                                        <option value="Missouri">Missouri</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Longitude</label>
                                    <input type="text" name="lng" id="lngVal" class="form-control" placeholder="Longitude" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Latitude</label>
                                    <input type="text" name="lat" id="latVal" class="form-control" placeholder="Latitude" value="">
                                </div>
                            </div>
                        </div>
                        <!-- Location End -->
                        <!-- Features Start -->
                        <div class="tab-pane fade" id="features" role="tabpanel" aria-labelledby="features-tab">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature1" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-bone"></i>
                                        Pet Friendly
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature2" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-chair"></i>
                                        Furnished
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature3" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-fan"></i>
                                        Cooling
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature4" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-garage"></i>
                                        Parking
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature5" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-mailbox"></i>
                                        Mailbox
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <label class="acr-listing-feature">
                                        <input type="checkbox" name="feature6" value="">
                                        <i class="acr-feature-check fas fa-check"></i>
                                        <i class="acr-listing-feature-icon flaticon-eye"></i>
                                        City View
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Features End -->
                        <!-- Details Start -->
                        <div class="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label>Property ID</label>
                                    <input type="text" class="form-control" placeholder="Property ID" name="id" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Beds</label>
                                    <input type="text" class="form-control" placeholder="Number of Beds" name="beds" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Bathrooms</label>
                                    <input type="text" class="form-control" placeholder="Number of Bathrooms" name="baths" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Condition</label>
                                    <input type="text" class="form-control" placeholder="Property Condition" name="condition" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Year Built</label>
                                    <input type="text" class="form-control" placeholder="Property Year Built" name="built" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Neighborhood</label>
                                    <input type="text" class="form-control" placeholder="Property Neighborhood" name="neighborhood" value="">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="termsAndConditions">
                                    <label class="custom-control-label" for="termsAndConditions">I Agree to the terms & Conditions of Property Submission</label>
                                </div>
                            </div>
                            <button type="submit" class="btn-custom" name="submit">Submit Listing</button>
                        </div>
                        <!-- Details End -->
                    </div>
                </form>
            </div>
            <!-- Tab Content End -->
        </div>
    </div>
</div>
<app-footer></app-footer>