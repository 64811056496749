<!-- Footer Start -->
<footer class="acr-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 footer-widget">
                <div class="footer-logo"> <img src="assets/img/logo1.png" alt="acres"> </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's </p>
                <ul class="social-media">
                    <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-pinterest-p"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                </ul>
            </div>
            <div class="col-lg-2 offset-md-1 col-md-4 col-sm-6 footer-widget">
                <h5 class="widget-title">Menu</h5>
                <ul>
                    <li> <a href="#">Find Home</a> </li>
                    <li> <a href="#">Add Listing</a> </li>
                    <li> <a href="#">Listings</a> </li>
                    <li> <a href="#">Blog</a> </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 footer-widget">
                <h5 class="widget-title">Legal</h5>
                <ul>
                    <li> <a href="#">Privacy Policy</a> </li>
                    <li> <a href="#">Refund Policy</a> </li>
                    <li> <a href="#">Cookie Policy</a> </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-12 footer-widget">
                <h5 class="widget-title">Newsletter</h5>
                <p>Join our newsletter today, and get the best flats that we have to offer.</p>
                <form method="post">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email Address" name="newsletter-email" value="">
                    </div>
                    <button type="submit" class="btn-custom primary light btn-block" name="button">Join Newsletter</button>
                </form>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <p class="m-0">&copy; Copyright 2020 - <a href="#">AndroThemes</a> All Rights Reserved.</p>
                </div>
                <div class="col-lg-5">
                    <ul>
                        <li> <a href="#">Find a Home</a> </li>
                        <li> <a href="#">Add Listing</a> </li>
                        <li> <a href="#">View Brokers</a> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer End -->
