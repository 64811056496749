<app-header-four></app-header-four>
<!-- Subheader Start -->
<div class="header-1"></div>
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">Agents</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink='/'> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Agents</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="section-two">
  <div class="youtube-video">
    <iframe width="620" height="350" src="https://www.youtube.com/embed/4IhtvyXCIbs" title="Homeowner Benefit Program Pays up to $5,000 | Call 833-MV-PAY-ME" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>

<div class="section-one">
  <div class="subtitle">
    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>
  </div>
</div>

<div class="section-two">
  <div class="row">
      <div class="column">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div class="column">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
  </div>
</div>

<div class="section-one">
  <div class="row row-image">
      <div class="column">
        <img src="assets/img/agents-info/house-placeholder.jpeg" alt="Palceholder" width="500" height="600">
      </div>
      <div class="column">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
  </div>
</div>

<div class="section-two">
  <div class="row row-image">
      <div class="column">
        <img src="assets/img/agents-info/house-placeholder.jpeg" alt="Palceholder" width="500" height="600">
      </div>
      <div class="column">
        <img src="assets/img/agents-info/house-placeholder.jpeg" alt="Palceholder" width="500" height="600">
      </div>
  </div>
</div>

<div class="section-one">
  <div class="subtitle">
    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>
  </div>
</div>

<div class="section-two">
  <div class="row row-image">
      <div class="column">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div class="column">
        <div class="youtube-video">
          <iframe width="620" height="350" src="https://www.youtube.com/embed/4IhtvyXCIbs" title="Homeowner Benefit Program Pays up to $5,000 | Call 833-MV-PAY-ME" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
  </div>
</div>

<app-footer></app-footer>
