<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader subheader-2 user-subheader bg-cover bg-center" style="background-image: url('assets/img/subheader-2.jpg')">
    <div class="container">
        <div class="media">
            <img src="assets/img/people/1.jpg" alt="agent">
            <div class="media-body">
                <h3 class="text-white">Randy Blue</h3>
                <span class="user-email">randy_blue@hotmail.com</span>
            </div>
            <a routerLink='/submit-listing' class="btn-custom secondary mr-0">Submit Listing <i class="fas mr-0 fa-plus"></i> </a>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="sidebar sticky-sidebar user-nav sidebar-left">
                    <ul>
                        <li> <a class="active" routerLink='/profile'> Edit Profile</a> </li>
                        <li> <a routerLink='/profile-listing'>My Listings</a> </li>
                        <li> <a routerLink='/profile-saved-listing'>Saved Listings</a> </li>
                        <li> <a class="logout" href="#"><i class="flaticon-shut-down-button"></i> Logout</a> </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="acr-welcome-message">
                    <h3>Welcome Back, Randy Blue</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <form method="post">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Full Name</label>
                            <input type="text" class="form-control" placeholder="Randy Blue" value="Randy Blue">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Username</label>
                            <input type="text" class="form-control" placeholder="randydandy" value="randydandy">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Email Address</label>
                            <input type="email" class="form-control" placeholder="randy_blue@hotmail.com" value="randy_blue@hotmail.com">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Phone Number</label>
                            <input type="text" class="form-control" placeholder="+123 456 789" value="+123 456 789">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Address One</label>
                            <input type="text" class="form-control" placeholder="Address" value="">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Address Two</label>
                            <input type="text" class="form-control" placeholder="Address" value="">
                        </div>
                        <div class="col-lg-12 form-group">
                            <label>About Me</label>
                            <textarea name="about" rows="4" class="form-control" placeholder="About Me"></textarea>
                        </div>
                    </div>
                    <button type="submit" name="submit" class="btn-custom">Save Changes</button>
                </form>
                <hr>
                <div class="acr-welcome-message">
                    <h3>Security</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
                <form method="post">
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password" value="">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label>Repeat Password</label>
                            <input type="password" class="form-control" placeholder="Repeat Password" value="">
                        </div>
                        <div class="col-lg-12 form-group">
                            <label>Upload Your ID</label>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="propertyThumbnail">
                                <label class="custom-file-label" for="propertyThumbnail">Choose file</label>
                            </div>
                        </div>
                    </div>
                    <button type="submit" name="submit" class="btn-custom">Save Changes</button>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>