import { HttpClient } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Testimonial, VideoTestimonial, testimonials } from './testimonials.data';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  @Input() backgroundColor: string;
  showMore = false;
  testimonials: Testimonial[] = null;
  videoContent: VideoTestimonial = null;

  constructor(private httpClient: HttpClient,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.testimonials = testimonials;
  }

  launchPlayer(content, vc: VideoTestimonial): void {
    this.videoContent = vc;
    this.modalService.open(content, { size: 'xl' });
  }

}