<!-- Header Start -->
<app-header-four></app-header-four>
<!-- Header Start -->
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">MV Agents</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Agents</li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<!-- Subheader End -->

<!-- Related Posts Start -->
<div class="container">

  <div class="sidebar-widget tags">
    <h5>&nbsp;</h5>
    <a (click)="onClick(brokerage.key)" *ngFor="let brokerage of groupedBrokerages | keyvalue" class="tag-anchor">{{brokerage.key}}</a>
  </div>

  <div class="section section-padding" *ngFor="let brokerage of groupedBrokerages | keyvalue" id="{{brokerage.key}}">
    <h4>{{brokerage.key}} AGENTS</h4>
    <div class="row">
      <div class="col-md-4" *ngFor="let agent of brokerage.value">
        <div class="acr-agent">
          <div class="listing-badge featured">
            <i class="fas fa-star"></i>
          </div>
          <div class="acr-dots-wrapper acr-agent-thumb">
            <div class="acr-dots"></div>
            <!--                <a routerLink='/agent-detail'><img src="assets/img/agents/1.jpg" alt="agent"></a>-->
            <img src="{{agent.profileImageLargeUrl}}" alt="{{agent.fullName}}">
          </div>
          <div class="acr-agent-body">
            <!--                <h6><a routerLink='/agent-detail'>{{agent.fullName}}</a></h6>-->
            <h6>{{agent.fullName}}</h6>
            <span>Expert at MV Realty - {{agent.brokerageName}}</span>
            <p>{{agent.agentBio}}</p>
            <!--                <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Related Posts End -->

<app-footer></app-footer>
