<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader subheader-2 user-subheader bg-cover bg-center" style="background-image: url('assets/img/subheader-2.jpg')">
    <div class="container">
        <div class="media">
            <img src="assets/img/people/1.jpg" alt="agent">
            <div class="media-body">
                <h3 class="text-white">Randy Blue</h3>
                <span class="user-email">randy_blue@hotmail.com</span>
            </div>
            <a routerLink='/submit-listing' class="btn-custom secondary mr-0">Submit Listing <i class="fas mr-0 fa-plus"></i></a>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="sidebar sticky-sidebar user-nav sidebar-left">
                    <ul>
                        <li> <a routerLink='/profile'> Edit Profile</a> </li>
                        <li> <a class="active" routerLink='/profile-listing'>My Listings</a> </li>
                        <li> <a routerLink='/profile-saved-listing'>Saved Listings</a> </li>
                        <li> <a class="logout" href="#"><i class="flaticon-shut-down-button"></i> Logout</a> </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8">
                <!-- Listing Start -->
                <div class="listing listing-list">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/1.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            <a href="#" class="edit"><i class="fas fa-edit"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">3</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">2,499</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
                <!-- Listing End -->
                <!-- Listing Start -->
                <div class="listing listing-list">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/2.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge sale"> On Sale</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            <a href="#" class="edit"><i class="fas fa-edit"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,300$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">1</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">1,780</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
                <!-- Listing End -->
                <!-- Listing Start -->
                <div class="listing listing-list">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/3.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            <a href="#" class="edit"><i class="fas fa-edit"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">3</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">2,499</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
                <!-- Listing End -->
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>