import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-secondary-banner-component',
  templateUrl: './secondary-banner.component.html',
  styleUrls: ['./secondary-banner.component.css']
})
export class SecondaryBannerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
