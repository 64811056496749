import {Component, OnInit} from '@angular/core';
import {ListingService} from '../../../../../service/listing.service';
import {GlobalConstants} from '../../../../../common/global-constants';

@Component({
  selector: 'app-recent-listings-component',
  templateUrl: './recent-listings.component.html',
  styleUrls: ['./recent-listings.component.css']
})
export class RecentListingsComponent implements OnInit {

  protected list = GlobalConstants;

  public slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '.slider-prev',
    nextArrow: '.slider-next'
  };

  public listings: any;
  public searchableLocations = this.list.searchableLocation;

  constructor(private listingService: ListingService) {
  }

  ngOnInit(): void {
    this.getDefaultListings();
  }

  async getDefaultListings(): Promise<void> {
    this.listingService.getListings(this.list.listingDefaultValues).then((result: any) => {
      this.listings = result.data;
    });
  }


}
