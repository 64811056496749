import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable()
export class AgentService {

  protected API_URL = environment.apiUrl;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get Agent List
   */
  getAgentList(): any {
    const url = `${this.API_URL}agents/all/active`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        const agents = list.filter((v, i, a) => a.findIndex(t => (t.agentId === v.agentId)) === i);
        resolve(agents);
      });
    });
  }

  /**
   * Get Agent List
   */
  getAgentListByBrokerage(brokerageId?, allAgents= null): any {
    const url = `${this.API_URL}agents/active-with-brokerage/${(brokerageId) ? brokerageId : ''}?allAgents=${allAgents}`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        let agents;
        if (list) {
          agents = list.filter((v, i, a) => a.findIndex(t => (t.agentId === v.agentId)) === i);
        }
        resolve(agents);
      });
    });
  }

  /**
   * Get Agent Spotlight
   */
  getSpotlightAgent(){
    const url = `${this.API_URL}agents/spotlight-agent`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((list: any) => {
        resolve(list);
      });
    });
  }


}
