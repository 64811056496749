import {Component, OnInit} from '@angular/core';
import {GlobalConstants} from '../../../../../common/global-constants';

@Component({
  selector: 'app-principal-banner-component',
  templateUrl: './principal-banner.component.html',
  styleUrls: ['./principal-banner.component.css']
})
export class PrincipalBannerComponent implements OnInit {

  public searchableLocations = GlobalConstants.searchableLocation;

  constructor() {
  }

  ngOnInit(): void {
  }

}
