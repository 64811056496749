<app-header-four></app-header-four>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">Privacy Policy</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink='/'> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<!-- Subheader End -->
<div class="section">
  <section class="padding-top-50">
    <div class="container">
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">PRIVACY POLICY</span></strong></strong></p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Last updated&nbsp;January 15, 2020</span></strong></strong>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;<span
        style="font-style: inherit; font-weight: inherit;">Thank you for choosing to be part of our community at&nbsp;MV Realty PBC, LLC&nbsp;(&ldquo;<strong><strong
        style="font-style: inherit;">Company</strong></strong>&rdquo;, &ldquo;<strong><strong
        style="font-style: inherit;">we</strong></strong>&rdquo;, &ldquo;<strong><strong
        style="font-style: inherit;">us</strong></strong>&rdquo;, or &ldquo;<strong><strong
        style="font-style: inherit;">our</strong></strong>&rdquo;). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our&nbsp;policy&nbsp;, or our practices with regards to your personal information, please contact us at&nbsp;info@mvrealtyfl.com.</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">When you visit our&nbsp;website&nbsp;https://mvrealtyfl.com,&nbsp;and use our services, you trust us with your personal information. We take your privacy very seriously. In this&nbsp;privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this&nbsp;privacy policy&nbsp;that you do not agree with, please discontinue use of our&nbsp;Sites&nbsp;and our services.&nbsp;</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">This&nbsp;privacy policy&nbsp;applies to all information collected through our&nbsp;website&nbsp;(such as&nbsp;https://mvrealtyfl.com),&nbsp;and/or any related services, sales, marketing or events (we refer to them collectively in this&nbsp;privacy policy&nbsp;as the "<strong><strong
        style="font-style: inherit;">Services</strong></strong>").&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Please read this&nbsp;privacy policy&nbsp;carefully as it will help you make informed decisions about sharing your personal information with us.&nbsp;&nbsp;</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">TABLE OF CONTENTS</span></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <ol>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WHAT INFORMATION DO WE COLLECT?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW DO WE USE YOUR INFORMATION?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></u>
        </li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></u>
        </li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE USE GOOGLE MAPS?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;</span></u>
        </li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW DO WE KEEP YOUR INFORMATION SAFE?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE COLLECT INFORMATION FROM MINORS?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WHAT ARE YOUR PRIVACY RIGHTS?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> CONTROLS FOR DO-NOT-TRACK FEATURES</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></u>
        </li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE MAKE UPDATES TO THIS POLICY?</span></u></li>
        <li style="font-weight: 400;"><u style="font-style: inherit; font-weight: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></u></li>
      </ol>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol>
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WHAT INFORMATION DO WE COLLECT?</span></strong></strong>
        </li>
      </ol>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Personal information you disclose to us</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We collect personal information that you provide to us such as name, address, contact information, passwords and security data,&nbsp;and payment information.</span></em>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We collect personal information that you voluntarily provide to us when&nbsp;registering at the&nbsp;Services&nbsp;expressing an interest in obtaining information about us or our products and services, when participating in activities on the&nbsp;Services&nbsp;or otherwise contacting us.</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">The personal information that we collect depends on the context of your interactions with us and the&nbsp;Services, the choices you make and the products and features you use. The personal information we collect can include the following:</span>
      </p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Publicly Available Personal Information.</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;We collect&nbsp;first name, maiden name, last name, and nickname;&nbsp;current and former address;&nbsp;phone numbers;&nbsp;email addresses;&nbsp;driver's license number;&nbsp;court records, lien filings, foreclosures, and UCC filings (forms filed by a creditor to give notice of interest in debtor's personal property);&nbsp;birth, marriage, divorce, and death records;&nbsp;real estate records including purchase and sale prices and&nbsp;neighbour&nbsp;info;&nbsp;family member names and their related information;&nbsp;and other similar data.</span>
      </p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Credentials.</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;We collect passwords, password hints, and similar security information used for authentication and account access.</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.&nbsp;&nbsp;</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Information automatically collected</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Some information &ndash; such as IP address and/or browser and device characteristics &ndash; is collected automatically when you visit our&nbsp;Services.</span></em>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We automatically collect certain information when you visit, use or navigate the&nbsp;Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our&nbsp;Services&nbsp;and other technical information. This information is primarily needed to maintain the security and operation of our&nbsp;Services, and for our internal analytics and reporting purposes.</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">Like many businesses, we also collect information through cookies and similar technologies.</span>
      </p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Online Identifiers.</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;We collect&nbsp;devices;&nbsp;tools and protocols, such as IP (Internet Protocol) addresses;&nbsp;cookie identifiers, or others such as the ones used for analytics and marketing;&nbsp;device's geolocation;&nbsp;and other similar data.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="2">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW DO WE USE YOUR INFORMATION?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We use personal information collected via our&nbsp;Services&nbsp;for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We use the information we collect or receive:</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <ul style="font-weight: 400;">
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To facilitate account creation and logon process.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;If you choose to link your account with us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To send you marketing and promotional communications.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "</span><a
          style="font-style: inherit; font-weight: inherit;"
          href="https://app.termly.io/dashboard/website/326673/privacy-policy#privacyrights"><span
          style="font-style: inherit; font-weight: inherit;">WHAT ARE YOUR PRIVACY RIGHTS</span></a><span
          style="font-style: inherit; font-weight: inherit;">" below).</span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To send administrative information to you.&nbsp;</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Deliver targeted advertising to you.&nbsp;</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Request Feedback.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may use your information to request feedback and to contact you about your use of our&nbsp;Services.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and Contractual.</span></strong></strong>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To respond to legal requests and prevent harm.&nbsp;</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To deliver services to the user.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may use your information to provide you with the requested service.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">To respond to user inquiries/offer support to users.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp; We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;</span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">For other Business Purposes.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our&nbsp;Services, products, marketing and your experience.&nbsp;We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent.</span>
        </li>
      </ul>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="3">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.&nbsp;</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We may process or share data based on the following legal basis:</span>
      </p>
      <ul style="font-weight: 400;">
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Consent:</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may process your data if you have given us specific consent to use your personal information in a specific purpose.&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;"><br/><br/><br/></span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Legitimate Interests:</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may process your data when it is reasonably necessary to achieve our legitimate business interests.&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;"><br/><br/><br/></span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Performance of a Contract:&nbsp;</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;"><br/><br/><br/></span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Legal Obligations:</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;"><br/><br/><br/></span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Vital Interests:</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span>
        </li>
      </ul>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">More specifically, we may need to process your data or share your personal information in the following situations:</span>
      </p>
      <ul style="font-weight: 400;">
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Vendors, Consultants and Other Third-Party Service Providers.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the&nbsp;Services, which will enable them to collect data about how you interact with the&nbsp;Services&nbsp;over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. &nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;</span><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Business Transfers.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Third-Party Advertisers.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may use third-party advertising companies to serve ads when you visit the&nbsp;Services. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Affiliates.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may share your information with our affiliates, in which case we will require those affiliates to honor this&nbsp;privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</span>
        </li>
        <li style="font-style: inherit; font-weight: inherit;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;">Business Partners.</span></strong></strong><span
          style="font-style: inherit; font-weight: inherit;">&nbsp;We may share your information with our business partners to offer you certain products, services or promotions.</span>
        </li>
      </ul>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="4">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We may use cookies and other tracking technologies to collect and store your information.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="5">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE USE GOOGLE MAPS?</span></strong></strong></li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Yes, we use Google Maps for the purpose of providing better service.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">This website, mobile application, or Facebook application uses Google Maps APIs. You may find the Google Maps APIs Terms of Service&nbsp;</span><a
        style="font-style: inherit; font-weight: inherit;" href="https://developers.google.com/maps/terms"><span
        style="font-style: inherit; font-weight: inherit;">here</span></a><span
        style="font-style: inherit; font-weight: inherit;">. To better understand Google&rsquo;s Privacy Policy, please refer to this&nbsp;</span><a
        style="font-style: inherit; font-weight: inherit;" href="https://policies.google.com/privacy"><span
        style="font-style: inherit; font-weight: inherit;">link</span></a><span
        style="font-style: inherit; font-weight: inherit;">.</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">By using our Maps API Implementation, you agree to be bound by Google&rsquo;s Terms of Service.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="6">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We keep your information for as long as necessary to fulfill the purposes outlined in this&nbsp;privacy policy&nbsp;unless otherwise required by law.&nbsp;</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We will only keep your personal information for as long as it is necessary for the purposes set out in this&nbsp;privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than&nbsp;the period of time in which users have an account with us.</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.&nbsp;</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="7">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We aim to protect your personal information through a system of organizational and technical security measures.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our&nbsp;Services&nbsp;is at your own risk. You should only access the services within a secure environment.&nbsp;</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="8">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE COLLECT INFORMATION FROM MINORS?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">We do not knowingly collect data from or market to children under 18 years of age.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We do not knowingly solicit data from or market to children under 18 years of age. By using the&nbsp;Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the&nbsp;Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at&nbsp;info@mvrealtyfl.com.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="9">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">You may review, change, or terminate your account at any time.</span></em>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span><a
        style="font-style: inherit; font-weight: inherit;"
        href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"><span
        style="font-style: inherit; font-weight: inherit;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a><span
        style="font-style: inherit; font-weight: inherit;">.</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">If you have questions or comments about your privacy rights, you may email us at&nbsp;info@mvrealtyfl.com.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">Account Information</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">If you would at any time like to review or change the information in your account or terminate your account, you can:</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">&nbsp; &nbsp; ■ &nbsp;Contact us using the contact information provided.&nbsp;&nbsp;</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</span>
      </p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><u
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Cookies and similar technologies:</span></u></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our&nbsp;Services. To opt-out of interest-based advertising by advertisers on our&nbsp;Services</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;visit&nbsp;</span><a
        style="font-style: inherit; font-weight: inherit;" href="http://www.aboutads.info/choices/"><span
        style="font-style: inherit; font-weight: inherit;">http://www.aboutads.info/choices/</span></a><span
        style="font-style: inherit; font-weight: inherit;">.</span></p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><u
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Opting out of email marketing:</span></u></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><span
        style="font-style: inherit; font-weight: inherit;">You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list &ndash; however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:&nbsp;</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">&nbsp; &nbsp; ■&nbsp;&nbsp;Contact us using the contact information provided.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="10">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this&nbsp;privacy policy.&nbsp;&nbsp;</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="11">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.&nbsp;</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">If you are under 18 years of age, reside in California, and have a registered account with the&nbsp;Services, you have the right to request removal of unwanted data that you publicly post on the&nbsp;Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the&nbsp;Services, but please be aware that the data may not be completely or comprehensively removed from our systems.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="12">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> DO WE MAKE UPDATES TO THIS POLICY?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">In Short:&nbsp;</span></em></strong></strong><strong><strong
        style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;</span></strong></strong><em
        style="font-style: inherit; font-weight: inherit;"><span style="font-style: inherit; font-weight: inherit;">Yes, we will update this policy as necessary to stay compliant with relevant laws.</span></em><span
        style="font-style: inherit; font-weight: inherit;">&nbsp;&nbsp;</span></p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">We may update this&nbsp;privacy policy&nbsp;from time to time. The updated version will be indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be effective as soon as it is accessible. If we make material changes to this&nbsp;privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this&nbsp;privacy policy&nbsp;frequently to be informed of how we are protecting your information.</span>
      </p>
      <p style="font-style: inherit; font-weight: inherit;">&nbsp;</p>
      <ol start="13">
        <li style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
          style="font-style: inherit; font-weight: inherit;"> HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></strong></strong>
        </li>
      </ol>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">If you have questions or comments about this policy, you may&nbsp;email us at&nbsp;info@mvrealtyfl.com&nbsp;or by post to:</span>
      </p>
      <p style="font-weight: 400;"><span style="font-style: inherit; font-weight: inherit;">MV Realty PBC, LLC</span>
      </p>
      <p style="font-weight: 400;"><strong><strong style="font-style: inherit;"><span
        style="font-style: inherit; font-weight: inherit;">219 N Dixie Blvd<br />
        Delray Beach, FL 33444<br />
        United States</span></strong></strong>
      </p>
    </div>
  </section>
</div>
<app-footer></app-footer>
