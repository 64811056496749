<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Agency Archive</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Agencies</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Agency Archive</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <!-- Agent Start -->
                    <div class="col-lg-6">
                        <div class="acr-agent">
                            <div class="listing-badge featured">
                                <i class="fas fa-star"></i>
                            </div>
                            <div class="acr-dots-wrapper acr-agent-thumb">
                                <div class="acr-dots"></div>
                                <a routerLink='/agency-detail'><img src="assets/img/companies/1.png" alt="agency"></a>
                            </div>
                            <div class="acr-agent-body">
                                <h6> <a routerLink='/agency-detail'>Envato Real Esate</a> </h6>
                                <span>Real Estate</span>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <a routerLink='/agency-detail' class="btn-custom secondary btn-sm">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- Agent End -->
                    <!-- Agent Start -->
                    <div class="col-lg-6">
                        <div class="acr-agent">
                            <div class="acr-dots-wrapper acr-agent-thumb">
                                <div class="acr-dots"></div>
                                <a routerLink='/agency-detail'><img src="assets/img/companies/2.png" alt="agency"></a>
                            </div>
                            <div class="acr-agent-body">
                                <h6> <a routerLink='/agency-detail'>Jumpy Co. Real Estate</a> </h6>
                                <span>Real Estate</span>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <a routerLink='/agency-detail' class="btn-custom secondary btn-sm">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- Agent End -->
                    <!-- Agent Start -->
                    <div class="col-lg-6">
                        <div class="acr-agent">
                            <div class="acr-dots-wrapper acr-agent-thumb">
                                <div class="acr-dots"></div>
                                <a routerLink='/agency-detail'><img src="assets/img/companies/3.png" alt="agency"></a>
                            </div>
                            <div class="acr-agent-body">
                                <h6> <a routerLink='/agency-detail'>Find Your Home</a> </h6>
                                <span>Real Estate</span>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <a routerLink='/agency-detail' class="btn-custom secondary btn-sm">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- Agent End -->
                    <!-- Agent Start -->
                    <div class="col-lg-6">
                        <div class="acr-agent">
                            <div class="listing-badge featured">
                                <i class="fas fa-star"></i>
                            </div>
                            <div class="acr-dots-wrapper acr-agent-thumb">
                                <div class="acr-dots"></div>
                                <a routerLink='/agency-detail'><img src="assets/img/companies/4.png" alt="agency"></a>
                            </div>
                            <div class="acr-agent-body">
                                <h6> <a routerLink='/agency-detail'>Homy Real Estate</a> </h6>
                                <span>Real Estate</span>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <a routerLink='/agency-detail' class="btn-custom secondary btn-sm">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- Agent End -->
                </div>
                <!-- Pagination Start -->
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-left"></i> </a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active">
                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-right"></i> </a></li>
                </ul>
                <!-- Pagination End -->
            </div>
            <div class="col-lg-4">
                <div class="sidebar">
                    <div class="sidebar-widget">
                        <h5>Recent Listings</h5>
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                                <span class="listing-price">3,500$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                                <span class="listing-price">1,300$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                                <span class="listing-price">2,500$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                                <span class="listing-price">1,900$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                    </div>
                    <div class="sidebar-widget sidebar-list">
                        <h5>Popular Categories</h5>
                        <ul>
                            <li> <a href="#"> Living Rooms <span>(24)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Real Estate <span>(16)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Kitchens <span>(32)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Flats <span>(22)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Luxury <span>(15)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Bed Rooms <span>(8)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>