<!-- Footer Start -->
<footer class="acr-footer footer-2">
  <!-- Footer Middle Start -->
  <div class="footer-middle">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-12 footer-widget">
          <div class="footer-logo"><img src="assets/img/logo1.png" alt="acres"></div>
        </div>
        <div class="col-lg-2 offset-lg-1 col-sm-4 footer-widget">
          <h5 class="widget-title">Menu</h5>
          <ul>
            <li><a routerLink='/team'>Team</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-sm-4 footer-widget">
          <h5 class="widget-title">Press</h5>
          <ul>
            <li><a href="mailto:pr@homesatmv.com">Press Inquiries</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-sm-4 footer-widget">
          <h5 class="widget-title">Information</h5>
          <ul>
            <li><a routerLink='/about'>About Us</a></li>
          </ul>
          <ul>
            <li><a routerLink="/contact">Contact</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-sm-4 footer-widget">
          <h5 class="widget-title">Legal</h5>
          <ul>
            <li><a routerLink='/policy'>Privacy Policy</a></li>
            <li><a routerLink='/terms'>Legal Terms</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Middle End -->
  <!-- Footer Bottom Start -->
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p class="m-0">&copy; Copyright 2021 - <a href="#">MV Realty PBC LLC</a> All Rights Reserved.</p>
        </div>
        <div class="col-lg-5">
          <ul>
            <li><a routerLink='/terms'>Terms and Conditions</a></li>
            <li><a routerLink='/policy'>Privacy Policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Bottom End -->
</footer>
<!-- Footer End -->
