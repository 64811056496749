import {Component, OnInit} from '@angular/core';
import {AgentService} from '../../../../../service/agent.service';

@Component({
  selector: 'app-agent-of-the-month-component',
  templateUrl: './agent-of-the-month.component.html',
  styleUrls: ['./agent-of-the-month.component.css']
})
export class AgentOfTheMonthComponent implements OnInit {

  public agent;

  constructor(protected agentService: AgentService) {
  }

  ngOnInit(): void {
    this.getAgent();
  }

  async getAgent() {
    const spotlightAgent: any = await this.agentService.getSpotlightAgent();
    const agents = await this.agentService.getAgentListByBrokerage();
    this.agent = agents
      .filter(e => e.agentId === spotlightAgent.agentId)[0];
  }


}
