<div class="sidebar">
    <div class="sidebar-widget">
        <form method="post">
            <h5>Search Posts</h5>
            <div class="search-wrapper">
                <input type="text" class="form-control" placeholder="Search" name="sidebar-search" value="">
                <button type="submit" class="btn-custom" name="button"><i class="flaticon-search"></i></button>
            </div>
        </form>
    </div>
    <div class="sidebar-widget">
        <h5>Recent Posts</h5>
        <article class="media">
            <a href="#"><img src="assets/img/blog-sm/1.jpg" alt="post"></a>
            <div class="media-body">
                <h6> <a href="#">Green neighborhood starts tomorrow</a> </h6>
                <span>January 22, 2020</span>
            </div>
        </article>
        <article class="media">
            <a href="#"><img src="assets/img/blog-sm/2.jpg" alt="post"></a>
            <div class="media-body">
                <h6> <a href="#">How to enable better agents in an agency</a> </h6>
                <span>January 24, 2020</span>
            </div>
        </article>
        <article class="media">
            <a href="#"><img src="assets/img/blog-sm/3.jpg" alt="post"></a>
            <div class="media-body">
                <h6> <a href="#">Real estate is booming in the west and east</a> </h6>
                <span>January 26, 2020</span>
            </div>
        </article>
        <article class="media">
            <a href="#"><img src="assets/img/blog-sm/4.jpg" alt="post"></a>
            <div class="media-body">
                <h6> <a href="#">What agencies are looking for in an agent</a> </h6>
                <span>January 28, 2020</span>
            </div>
        </article>
    </div>
    <div class="sidebar-widget sidebar-list">
        <h5>Popular Categories</h5>
        <ul>
            <li> <a href="#"> Living Rooms <span>(24)</span> <i class="fas fa-chevron-right"></i> </a> </li>
            <li> <a href="#"> Real Estate <span>(16)</span> <i class="fas fa-chevron-right"></i> </a> </li>
            <li> <a href="#"> Kitchens <span>(32)</span> <i class="fas fa-chevron-right"></i> </a> </li>
            <li> <a href="#"> Flats <span>(22)</span> <i class="fas fa-chevron-right"></i> </a> </li>
            <li> <a href="#"> Luxury <span>(15)</span> <i class="fas fa-chevron-right"></i> </a> </li>
            <li> <a href="#"> Bed Rooms <span>(8)</span> <i class="fas fa-chevron-right"></i> </a> </li>
        </ul>
    </div>
    <div class="sidebar-widget tags">
        <h5>Popular Tags</h5>
        <a href="#">House</a>
        <a href="#">Real Estate</a>
        <a href="#">Building</a>
        <a href="#">Agency</a>
        <a href="#">Family</a>
        <a href="#">Acres</a>
        <a href="#">Baths</a>
        <a href="#">Rooms</a>
    </div>
</div>