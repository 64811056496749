<app-header-four></app-header-four>
<div class="header-1"></div>
<!-- Banner Start -->
<div class="banner banner-2 slider-no-padding">
  <div class="banner-item">

    <ngx-slick-carousel
      *ngIf="property && property.property_images"
      class="listings-slider"
      #slickModal="slick-carousel"
      [config]="slideConfig">
      <div ngxSlickItem *ngFor="let image of property.property_images.slice(0,2)">
        <div class="banner-inner bg-cover bg-center" style="background-image: url({{image.property_image_url}})"></div>
      </div>
    </ngx-slick-carousel>

    <div class="listings-slider-loading">

    </div>

    <div class="acr-listing-details" *ngIf="listing">
      <div class="acr-listing-section">
        <div class="acr-listing-nav">
          <a href="#" class="btn-custom secondary">Print Listing</a>
        </div>
        <div class="acr-listing-section-body">
          <div class="acr-listing-section-price">
            <span>For Sale</span>
            <h3>{{listing.listing_price_usd | currency }}</h3>
            <span>Est. Mortgage</span>
            <p>{{listing.listing_price_usd |  monthlyMortgage | currency }}/mo</p>
          </div>
        </div>
      </div>
      <div class="acr-listing-section">
        <div class="acr-listing-section-body">
          <h4>{{listing.address}} - {{listing.listing_city_original}}</h4>
          <div class="acr-listing-icons">
            <div class="acr-listing-icon">
              <i class="flaticon-bedroom"></i>
              <span>Beds</span>
              <span class="acr-listing-icon-value">{{listing.listing_bedroom_count}}</span>
            </div>
            <div class="acr-listing-icon">
              <i class="flaticon-bathroom"></i>
              <span>Baths</span>
              <span class="acr-listing-icon-value">{{listing.listing_full_bath_count}}</span>
            </div>
            <div class="acr-listing-icon">
              <i class="flaticon-ruler"></i>
              <span>Sqft</span>
              <span class="acr-listing-icon-value">{{listing.listing_structure_area_sq_ft | number}}</span>
            </div>
          </div>
          <p>{{listing.listing_solicitation_txt | titlecase | slice:0:100 }}</p>
        </div>
      </div>
      <div class="acr-listing-section">
        <div class="acr-listing-controls">
          <a href="#" class="acr-listing-control">
            <i class="flaticon-share"></i>
          </a>
          <a href="#" class="acr-listing-control">
            <i class="flaticon-star"></i>
          </a>
          <a href="#" class="acr-schedule-tour acr-listing-control">
            <i class="flaticon-event"></i>
            <span>Schedule a Tour</span>
          </a>
        </div>
        <div class="acr-listing-section-body">
          <div class="acr-listing-meta">
            <div class="row">
              <div class="col-lg-6 col-md-3 col-sm-3">
                <div class="acr-listing-meta-item">
                  <span>Type</span>
                  <p>{{listing.listing_residence_type_cd}}</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-3 col-sm-3">
                <div class="acr-listing-meta-item">
                  <span>View</span>
                  <p>City View</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-3 col-sm-3">
                <div class="acr-listing-meta-item">
                  <span>Size</span>
                  <p>{{listing.listing_structure_area_sq_ft}} sqft.</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-3 col-sm-3">
                <div class="acr-listing-meta-item">
                  <span>Construction Year</span>
                  <p>{{listing.listing_construction_year}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="acr-arrows">
    <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
    <i class="slider-next fas fa-arrow-right slick-arrow"></i>
  </div>
</div>
<!-- Banner End -->
<div class="section listing-wrapper">
  <div class="container" *ngIf="listing">
    <div class="row">
      <!-- Listings Start -->
      <div class="col-lg-8">
        <!-- Content Start -->
        <div class="listing-content">
          <h4>Property Overview</h4>
          <p>{{listing.listing_solicitation_txt | titlecase}}</p>

          <ngx-slick-carousel
            *ngIf="property && property.property_images"
            class="row"
            #slickModal="slick-carousel"
            [config]="middleSlideConfig">
            <div ngxSlickItem *ngFor="let image of property.property_images">
              <div class="gallery-thumb">
                <img src="{{image.property_image_url}}" alt="post">
              </div>
            </div>
          </ngx-slick-carousel>

          <div class="acr-arrows transformed-medium">
            <i class="slider-prev1 fas fa-arrow-left slick-arrow"></i>
            <i class="slider-next1 fas fa-arrow-right slick-arrow"></i>
          </div>

        </div>
        <!-- Content End -->
        <!-- Price Range In the area Start -->
<!--        <div class="section">-->
<!--          <div class="acr-area-price">-->
<!--            <span style="left: 30%">852,000$</span>-->
<!--            <div class="progress">-->
<!--              <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0"-->
<!--                   aria-valuemax="100"></div>-->
<!--            </div>-->
<!--            <div class="acr-area-price-wrapper">-->
<!--              <div class="acr-area-price-min">-->
<!--                <h5>562,000$</h5>-->
<!--                <span>Lowest</span>-->
<!--              </div>-->
<!--              <h5>Price range in the area</h5>-->
<!--              <div class="acr-area-price-max">-->
<!--                <h5>1,280,000$</h5>-->
<!--                <span>Highest</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Price Range In the area End -->
        <div class="section section-padding pt-0 acr-listing-features">
          <h4>Features</h4>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="listing-feature-wrapper">
                <div class="listing-feature">
                  <i class="flaticon-picture"></i>
                  <h6 class="listing-feature-label">Property Type</h6>
                  <span class="listing-feature-value">{{listing.listing_residence_type_cd}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-bone"></i>
                  <h6 class="listing-feature-label">Pet Friendly</h6>
                  <span class="listing-feature-value">{{(listing.listing_allow_pet_ind)?'Yes':'No'}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-chair"></i>
                  <h6 class="listing-feature-label">Furnished</h6>
                  <span class="listing-feature-value">{{(listing.listing_furnished_ind)?'Yes':'No'}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-fan"></i>
                  <h6 class="listing-feature-label">Cooling</h6>
                  <span class="listing-feature-value">{{(listing.listing_heating_climate_control_cd).replaceAll('_', ' ') | titlecase }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="listing-feature-wrapper">
                <div class="listing-feature">
                  <i class="flaticon-bathroom"></i>
                  <h6 class="listing-feature-label">Bathrooms</h6>
                  <span class="listing-feature-value">{{listing.listing_full_bath_count}}/{{(listing.listing_half_bath_count)?listing.listing_half_bath_count:'0'}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-pillow"></i>
                  <h6 class="listing-feature-label">Bed Rooms</h6>
                  <span class="listing-feature-value">{{listing.listing_bedroom_count}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-stairs"></i>
                  <h6 class="listing-feature-label">Floors Count</h6>
                  <span class="listing-feature-value">{{listing.listing_floors_count}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-ruler"></i>
                  <h6 class="listing-feature-label">Property Size</h6>
                  <span class="listing-feature-value">{{listing.listing_structure_area_sq_ft | number}} Sqft</span>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="load-more-features btn-custom-2 light-grey btn-block" name="button">Show More
          </button>
          <div class="hidden-listing-features">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="listing-feature">
                  <i class="flaticon-key"></i>
                  <h6 class="listing-feature-label">Property Id</h6>
                  <span class="listing-feature-value">{{listing.listing_mls_public_id}}</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-garage"></i>
                  <h6 class="listing-feature-label">Parking **</h6>
                  <span class="listing-feature-value">Yes</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-history"></i>
                  <h6 class="listing-feature-label">Year Built</h6>
                  <span class="listing-feature-value">{{listing.listing_construction_year}}</span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="listing-feature">
                  <i class="flaticon-new"></i>
                  <h6 class="listing-feature-label">Condition **</h6>
                  <span class="listing-feature-value">New</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-ruler"></i>
                  <h6 class="listing-feature-label">Lot Size</h6>
                  <span class="listing-feature-value">{{listing.listing_lot_area_sq_ft}} Sqft</span>
                </div>
                <div class="listing-feature">
                  <i class="flaticon-eye"></i>
                  <h6 class="listing-feature-label">View **</h6>
                  <span class="listing-feature-value">City View</span>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="section pt-0 acr-listing-nearby">-->
<!--          <h4>What's Nearby</h4>-->
<!--          <div class="listing-nearby-item">-->
<!--            <h6 class="custom-success">-->
<!--              <i class="fas fa-utensils"></i>Restaurants-->
<!--            </h6>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <span><b>The One</b> <span>(1.3 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>Elephant</b> <span>(0.2 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>Jorgie and Clyne</b> <span>(3.2 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="listing-nearby-item">-->
<!--            <h6 class="custom-danger">-->
<!--              <i class="fas fa-graduation-cap"></i>Education-->
<!--            </h6>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <span><b>Greater Moscow Liberty School</b> <span>(1.3 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>Chandler Highschool</b> <span>(0.2 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>Insitution of Science and Technology</b> <span>(3.2 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="listing-nearby-item">-->
<!--            <h6 class="custom-warning">-->
<!--              <i class="fas fa-shopping-basket"></i>Essentials-->
<!--            </h6>-->
<!--            <ul>-->
<!--              <li>-->
<!--                <span><b>Joe's 24/7 Groceries</b> <span>(0.6 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>Frank and Joe</b> <span>(0.9 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li>-->
<!--                <span><b>All United Store</b> <span>(1.2 km)</span> </span>-->
<!--                <ul>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                  <li class="custom-warning"><i class="fas fa-star fa-xs"></i></li>-->
<!--                </ul>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="section pt-0">-->
<!--          <h4>Property Video</h4>-->
<!--          <p>-->
<!--            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the-->
<!--            industry's standard dummy text ever since the 1500s,-->
<!--            when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived-->
<!--            not only five centuries, but also the leap-->
<!--          </p>-->
<!--          <div class="embed-responsive embed-responsive-21by9">-->
<!--            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Sz_1tkcU0Co"></iframe>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="section pt-0 acr-listing-history">-->
<!--          <h4>Property History</h4>-->
<!--          <div class="accordion with-gap" id="propertyHistory">-->
<!--            <div class="card">-->
<!--              <div class="card-header" data-toggle="collapse" role="button" data-target="#historyOne"-->
<!--                   aria-expanded="true" aria-controls="historyOne">-->
<!--                1979 - 1999-->
<!--              </div>-->
<!--              <div id="historyOne" class="collapse show" data-parent="#propertyHistory">-->
<!--                <div class="card-body">-->
<!--                  <div class="row">-->
<!--                    <div class="col-sm-4">-->
<!--                      <img src="assets/img/listing-single/history-1.jpg" alt="property history">-->
<!--                    </div>-->
<!--                    <div class="col-sm-8">-->
<!--                      <h5>The Beginning</h5>-->
<!--                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3-->
<!--                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum-->
<!--                      eiusmod. Brunch 3 wolf moon-->
<!--                      tempor,-->
<!--                      sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim-->
<!--                      keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.-->
<!--                      Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim-->
<!--                      aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header" data-toggle="collapse" role="button" data-target="#historyTwo"-->
<!--                   aria-expanded="false" aria-controls="historyTwo">-->
<!--                2000 - 2012-->
<!--              </div>-->
<!--              <div id="historyTwo" class="collapse" data-parent="#propertyHistory">-->
<!--                <div class="card-body">-->
<!--                  <div class="row">-->
<!--                    <div class="col-sm-4">-->
<!--                      <img src="assets/img/listing-single/history-2.jpg" alt="property history">-->
<!--                    </div>-->
<!--                    <div class="col-sm-8">-->
<!--                      <h5>The Rebuilding Phase</h5>-->
<!--                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3-->
<!--                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum-->
<!--                      eiusmod. Brunch 3 wolf moon-->
<!--                      tempor,-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="card">-->
<!--              <div class="card-header" data-toggle="collapse" role="button" data-target="#historyThree"-->
<!--                   aria-expanded="false" aria-controls="historyThree">-->
<!--                2013 - Till date-->
<!--              </div>-->
<!--              <div id="historyThree" class="collapse" data-parent="#propertyHistory">-->
<!--                <div class="card-body">-->
<!--                  <div class="row">-->
<!--                    <div class="col-sm-4">-->
<!--                      <img src="assets/img/listing-single/history-3.jpg" alt="property history">-->
<!--                    </div>-->
<!--                    <div class="col-sm-8">-->
<!--                      <h5>Modernization</h5>-->
<!--                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3-->
<!--                      wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum-->
<!--                      eiusmod. Brunch 3 wolf moon-->
<!--                      tempor,-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="section pt-0">-->
<!--          <h4>Schedule a Tour</h4>-->
<!--          <form method="post">-->
<!--            <div class="row">-->
<!--              <div class="col-md-6 form-group">-->
<!--                <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">-->
<!--              </div>-->
<!--              <div class="col-md-6 form-group">-->
<!--                <input type="email" class="form-control" placeholder="Email Address" name="email" value="">-->
<!--              </div>-->
<!--              <div class="col-md-6 form-group">-->
<!--                <input type="text" class="form-control" placeholder="Phone Number" name="phone" value="">-->
<!--              </div>-->
<!--              <div class="col-md-6 form-group">-->
<!--                <input type="text" class="form-control" placeholder="Date" name="date" value="">-->
<!--              </div>-->
<!--              <div class="col-md-12 form-group">-->
<!--                <textarea class="form-control" placeholder="Type your comment..." name="comment" rows="7"></textarea>-->
<!--              </div>-->
<!--            </div>-->
<!--            <button type="submit" class="btn-custom primary" name="button">Schedule Tour</button>-->
<!--          </form>-->
<!--        </div>-->
        <!-- Pagination Start -->
<!--        <div class="section p-0 post-single-pagination-wrapper">-->
<!--          <div class="post-single-pagination post-prev">-->
<!--            <i class="fas fa-arrow-left"></i>-->
<!--            <a href="#" class="post-single-pagination-content">-->
<!--              <span>Prev Listing</span>-->
<!--              <h6>Theodore Lowe, Azusa New York 39531</h6>-->
<!--            </a>-->
<!--          </div>-->
<!--          <div class="post-single-pagination post-next">-->
<!--            <a href="#" class="post-single-pagination-content">-->
<!--              <span>Next Listing</span>-->
<!--              <h6>Cecilia Chapman, Mankato Mississippi 96522</h6>-->
<!--            </a>-->
<!--            <i class="fas fa-arrow-right"></i>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Pagination End -->
        <!-- Similar Start -->
<!--        <div class="section section-padding">-->
<!--          <h4>Similar Listings</h4>-->
<!--          <div class="row">-->
<!--            &lt;!&ndash; Listing Start &ndash;&gt;-->
<!--            <div class="col-md-6">-->
<!--              <div class="listing">-->
<!--                <div class="listing-thumbnail">-->
<!--                  <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>-->
<!--                  <div class="listing-badges">-->
<!--                    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>-->
<!--                    <span class="listing-badge pending"> Pending</span>-->
<!--                  </div>-->
<!--                  <div class="listing-controls">-->
<!--                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>-->
<!--                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="listing-body">-->
<!--                  <div class="listing-author">-->
<!--                    <img src="assets/img/people/2.jpg" alt="author">-->
<!--                    <div class="listing-author-body">-->
<!--                      <p><a href="#">Heather Mclayn</a></p>-->
<!--                      <span class="listing-date">March 3, 2020</span>-->
<!--                    </div>-->
<!--                    <div class="dropdown options-dropdown">-->
<!--                      <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i-->
<!--                        class="fas fa-ellipsis-v"></i></a>-->
<!--                      <div class="dropdown-menu dropdown-menu-right">-->
<!--                        <ul>-->
<!--                          <li><a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a></li>-->
<!--                          <li><a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a></li>-->
<!--                          <li><a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a></li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <h5 class="listing-title"><a routerLink='/listing-detail-v1'-->
<!--                                               title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick-->
<!--                    Nebraska 20620</a></h5>-->
<!--                  <span class="listing-price">3,500$ <span>/month</span> </span>-->
<!--                  <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>-->
<!--                  <div class="acr-listing-icons">-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">-->
<!--                      <i class="flaticon-bedroom"></i>-->
<!--                      <span class="acr-listing-icon-value">3</span>-->
<!--                    </div>-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">-->
<!--                      <i class="flaticon-bathroom"></i>-->
<!--                      <span class="acr-listing-icon-value">2</span>-->
<!--                    </div>-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">-->
<!--                      <i class="flaticon-ruler"></i>-->
<!--                      <span class="acr-listing-icon-value">2,499</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="listing-gallery-wrapper">-->
<!--                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>-->
<!--                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i-->
<!--                      class="fas fa-camera"></i> </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Listing End &ndash;&gt;-->
<!--            &lt;!&ndash; Listing Start &ndash;&gt;-->
<!--            <div class="col-md-6">-->
<!--              <div class="listing">-->
<!--                <div class="listing-thumbnail">-->
<!--                  <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>-->
<!--                  <div class="listing-badges">-->
<!--                    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>-->
<!--                    <span class="listing-badge sale"> On Sale</span>-->
<!--                  </div>-->
<!--                  <div class="listing-controls">-->
<!--                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>-->
<!--                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="listing-body">-->
<!--                  <div class="listing-author">-->
<!--                    <img src="assets/img/people/1.jpg" alt="author">-->
<!--                    <div class="listing-author-body">-->
<!--                      <p><a href="#">Randy Blue</a></p>-->
<!--                      <span class="listing-date">March 3, 2020</span>-->
<!--                    </div>-->
<!--                    <div class="dropdown options-dropdown">-->
<!--                      <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i-->
<!--                        class="fas fa-ellipsis-v"></i></a>-->
<!--                      <div class="dropdown-menu dropdown-menu-right">-->
<!--                        <ul>-->
<!--                          <li><a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a></li>-->
<!--                          <li><a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a></li>-->
<!--                          <li><a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a></li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <h5 class="listing-title"><a routerLink='/listing-detail-v1'-->
<!--                                               title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York-->
<!--                    39531</a></h5>-->
<!--                  <span class="listing-price">2,300$ <span>/month</span> </span>-->
<!--                  <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>-->
<!--                  <div class="acr-listing-icons">-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">-->
<!--                      <i class="flaticon-bedroom"></i>-->
<!--                      <span class="acr-listing-icon-value">2</span>-->
<!--                    </div>-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">-->
<!--                      <i class="flaticon-bathroom"></i>-->
<!--                      <span class="acr-listing-icon-value">1</span>-->
<!--                    </div>-->
<!--                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">-->
<!--                      <i class="flaticon-ruler"></i>-->
<!--                      <span class="acr-listing-icon-value">1,780</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="listing-gallery-wrapper">-->
<!--                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>-->
<!--                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i-->
<!--                      class="fas fa-camera"></i> </a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Listing End &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <!-- Similar End -->
      </div>
      <!-- Listings End -->
      <!-- Sidebar Start -->
      <div class="col-lg-4">
        <div class="sidebar sticky-sidebar">
          <div class="sidebar-widget">
            <h5>Meet The Agent</h5>
            <!-- Author Start -->
            <div class="media sidebar-author listing-agent">
              <a href="#"><img src="assets/img/people/1.jpg" alt="agent"></a>
              <div class="media-body">
                <h6><a href="#">Freddy Burben</a></h6>
                <span>Company Agent</span>
              </div>
              <div class="dropdown options-dropdown">
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                  class="fas fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <ul>
                    <li><a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a></li>
                    <li><a routerLink='/listing-grid'> <i class="fas fa-th-list"></i> View Listings</a></li>
                    <li><a href="#"> <i class="fas fa-star"></i> Save Agent</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Author End -->
            <!-- Contact Start -->
            <form method="post">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Email Address" name="email" value="">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Phone Number" name="phone" value="">
              </div>
              <div class="form-group">
                <textarea name="message" rows="3" placeholder="Enter your message" class="form-control"></textarea>
              </div>
              <a href="#" class="btn-custom primary light btn-block">Send Message</a>
            </form>
            <!-- Contact End -->
          </div>
          <div class="sidebar-widget">
            <h5>Recent Listings</h5>
            <!-- Listing Start -->
            <div class="listing listing-list">
              <div class="listing-thumbnail">
                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
              </div>
              <div class="listing-body">
                <h6 class="listing-title"><a routerLink='/listing-detail-v1'
                                             title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick
                  Nebraska 20620</a></h6>
                <span class="listing-price">3,500$ <span>/month</span> </span>
              </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
              <div class="listing-thumbnail">
                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
              </div>
              <div class="listing-body">
                <h6 class="listing-title"><a routerLink='/listing-detail-v1'
                                             title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York
                  39531</a></h6>
                <span class="listing-price">1,300$ <span>/month</span> </span>
              </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
              <div class="listing-thumbnail">
                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
              </div>
              <div class="listing-body">
                <h6 class="listing-title"><a routerLink='/listing-detail-v1'
                                             title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick
                  Nebraska 20620</a></h6>
                <span class="listing-price">2,500$ <span>/month</span> </span>
              </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
              <div class="listing-thumbnail">
                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
              </div>
              <div class="listing-body">
                <h6 class="listing-title"><a routerLink='/listing-detail-v1'
                                             title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York
                  39531</a></h6>
                <span class="listing-price">1,900$ <span>/month</span> </span>
              </div>
            </div>
            <!-- Listing End -->
          </div>
          <div class="sidebar-widget">
            <h5>Mortgage Calculator</h5>
            <form method="post">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Total Amount" name="total" value="">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Down Payment" name="down-p" value="">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Interest Rate" name="interest" value="">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Loan Term" name="loan-term" value="">
              </div>
              <div class="form-group">
                <select class="form-control" name="cycle">
                  <option value="Daily">Daily</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
              <button type="submit" class="btn-custom btn-block" name="button">Calculate</button>
            </form>
          </div>
        </div>
      </div>
      <!-- Sidebar End -->
    </div>
  </div>
</div>
<app-footer></app-footer>
