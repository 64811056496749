import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumberUtil} from '../utils/phone-number-util';

@Pipe({
  name: 'localPhoneNumber'
})
export class LocalPhoneNumberPipe implements PipeTransform {

  private utilInstance = new PhoneNumberUtil();

  transform(phone: any): any {
    if (!phone) {
      return null;
    }
    try {
      return this.utilInstance.toUSPhoneFormat(phone);
    } catch (e) {
      console.log(e);
    }
    return phone;
  }

}
