import {Component, OnInit} from '@angular/core';
import {ListingService} from '../../../../../service/listing.service';
import {GlobalConstants} from '../../../../../common/global-constants';

@Component({
  selector: 'app-top-listings-component',
  templateUrl: './top-listings.component.html',
  styleUrls: ['./top-listings.component.css']
})
export class TopListingsComponent implements OnInit {

  protected list = GlobalConstants;

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    prevArrow: '.slider-prev-top',
    nextArrow: '.slider-next-top',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
        }
      },
    ]
  };

  public listings: any;
  public searchableLocations = this.list.searchableLocation;

  constructor(private listingService: ListingService) {
  }

  ngOnInit(): void {
    this.list.listingDefaultValues.mv_listings = 1;
    this.getDefaultListings();
  }

  async getDefaultListings(): Promise<void> {
    this.listingService.getListings(this.list.listingDefaultValues).then((result: any) => {
      this.listings = result.data;
    });
  }


}
