<!-- Aside (Mobile Navigation) -->
<aside class="main-aside">
  <div class="aside-title">
    <div class="aside-controls aside-trigger">
      <h4>Menu</h4>
      <div class="close-btn close-dark">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <div class="aside-scroll">
    <ul>
      <!-- Social Media Start -->
      <li class="menu-section-title"><a href="./">Home</a></li>
      <li class="menu-item"><a href="./team">Our Team</a></li>
      <li class="menu-item"><a href="./about">About</a></li>
      <li class="menu-item"><a href="http://mvrealtycareers.com">Careers</a></li>
      <li class="menu-item"><a href="./testimonials">Testimonials</a></li>
      <li class="menu-item"><a href="./press">Press</a></li>
      <li class="menu-item"><a href="./contact">Contact</a></li>
      <li class="menu-item"><a href="http://mvarticles.com">Blog</a></li>
      <li class="menu-item"><a href="https://homeownerbenefit.com/?src=9" target="_blank">Homeowner Benefit Program ®</a></li>
      <!-- Social Media End -->
    </ul>
  </div>
</aside>
<div class="aside-overlay aside-trigger"></div>
<!-- Header Start -->
<header class="main-header">
  <nav class="navbar">
    <div class="container">
      <!-- Logo -->
      <a class="navbar-brand lg-logo" routerLink='/'> <img src="assets/img/logo1.png" alt="MV Realty FL"> </a>
      <a class="navbar-brand xs-logo" routerLink='/'> <img src="assets/img/logo_sm.png" alt="MV Realty FL"> </a>
      <!-- Menu -->
      <ul class="navbar-nav">
        <li class="menu-item">
          <a routerLink='/team'>Our Team</a>
        </li>
        <li class="menu-item">
          <a routerLink='/about'>About</a>
        </li>
        <li class="menu-item">
            <a href="http://mvrealtycareers.com">Careers</a>
        </li>
        <li class="menu-item">
            <a routerLink='/testimonials'>Testimonials</a>
        </li>
        <li class="menu-item">
            <a routerLink='/press'>Press</a>
        </li>
        <li class="menu-item">
          <a routerLink='/contact'>Contact</a>
        </li>
        <li class="menu-item">
          <a href="http://mvarticles.com">Blog</a>
        </li>
        <li class="menu-item">
          <a href="https://homeownerbenefit.com/?src=9" target="_blank">Homeowner Benefit Program ®</a>
        </li>
      </ul>

      <div class="bg-right">
        <h6><i class="fa fa-phone"></i> Call Us : <a class="custom-primary" href="tel:+18664843824">866-484-3824</a>
        </h6>
      </div>

      <div class="header-controls">
        <!-- Toggler -->
        <div class="aside-toggler aside-trigger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </nav>
</header>
<!-- Header End -->
