import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-listing-component',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

  @Input() listingInfo: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  noImage(e){
    e.target.src = 'assets/img/listings/noimage.png';
  }

}
