<app-header-three></app-header-three>
<!-- Banner Start -->
<div class="header-1"></div>
<div class="banner banner-1 bg-cover bg-center" style="background-image: url('assets/img/banner/15.jpg')">
    <div class="container">
        <div class="banner-item">
            <div class="banner-inner">
                <div class="banner-text">
                    <h1 class="title">Real Estate Agents Near You</h1>
                    <p class="subtitle">Thousands of people have their flats up for grabs. Don't miss your chance to grab your own house today.</p>
                </div>
                <div class="acr-filter-form">
                    <form method="post">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Location: </label>
                                    <select class="acr-select2" name="location">
                                        <option value="" label="Location"></option>
                                        <option value="*">Any Location</option>
                                        <option value="California, CA">California, CA</option>
                                        <option value="Lawndale, CA">Lawndale, CA</option>
                                        <option value="Stroudsburg, PA">Stroudsburg, PA</option>
                                        <option selected value="West Roxbury, MA">West Roxbury, MA</option>
                                        <option value="Ponte Vedra Beach, FL">Ponte Vedra Beach, FL</option>
                                        <option value="Fort Worth, TX">Fort Worth, TX</option>
                                        <option value="Willingboro, NJ">Willingboro, NJ</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Status: </label>
                                    <select class="acr-select2" name="status">
                                        <option value="" label="Status"></option>
                                        <option selected value="*">Any Status</option>
                                        <option value="For Rent">For Rent</option>
                                        <option value="Featured">Featured</option>
                                        <option value="For Sale">For Sale</option>
                                        <option value="Leased">Leased</option>
                                        <option value="New Addition">New Addition</option>
                                        <option value="Sold">Sold</option>
                                        <option value="Rental">Rental</option>
                                        <option value="Reduced">Reduced</option>
                                        <option value="Special Offer">Special Offer</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Price Range: </label>
                                    <select class="acr-select2" name="price">
                                        <option value="" label="Price Range"></option>
                                        <option selected value="*">Any Range</option>
                                        <option value="$60k - $80k">$60k - $80k</option>
                                        <option value="$80k - $100k">$80k - $100k</option>
                                        <option value="$100k - $120k">$100k - $120k</option>
                                        <option value="$120k - $140k">$120k - $140k</option>
                                        <option value="$140k - $160k">$140k - $160k</option>
                                        <option value="$160k - $180k">$160k - $180k</option>
                                        <option value="$180k - $200k">$180k - $200k</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6">
                                <div class="form-group">
                                    <button type="submit" class="btn-custom btn-block" name="button">Search listings</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Banner End -->
<!-- Icons Start -->
<div class="section section-padding infographics-3">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Our Moto</h5>
            <h2 class="title">Why Choose Us</h2>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="acr-infographic-item">
                    <i class="flaticon-star"></i>
                    <div class="acr-infographic-item-body">
                        <h5>We Are Featured</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="acr-infographic-item">
                    <i class="flaticon-apartment"></i>
                    <div class="acr-infographic-item-body">
                        <h5>Unlimited Listings</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="acr-infographic-item">
                    <i class="flaticon-sales-agent"></i>
                    <div class="acr-infographic-item-body">
                        <h5>Great Support</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Icons End -->
<!-- About Us Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 acr-single-img-wrapper mb-lg-30">
                <div class="section-title-wrap section-header acr-dots-wrapper">
                    <h2 class="title">We Advertise Houses That Sell</h2>
                    <div class="acr-dots"></div>
                </div>
                <img src="assets/img/listings-list/6.jpg" alt="listing">
            </div>
            <div class="col-lg-6 acr-single-img-wrapper">
                <div class="section-title-wrap">
                    <img src="assets/img/about/1.jpg" alt="listing">
                    <p class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <a routerLink='/listing-map' class="btn-link custom-primary">Browse Listings <i class="fas fa-arrow-right"></i> </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Us End -->
<!-- Browse By Location Start -->
<div class="section section-padding bg-norepeat bg-bottom light-bg" style="background-image: url('assets/img/misc/bldg.png')">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Find Your Paradise</h5>
            <h2 class="title">Browse By City</h2>
        </div>
        <div class="row masonry">
            <div class="col-lg-6 masonry-item">
                <div class="acr-country-item acr-country-item-lg">
                    <div class="acr-country-thumb">
                        <img src="assets/img/countries/6.jpg" alt="country">
                    </div>
                    <a routerLink='/listing-map' class="acr-country-content">
                        <h4>New Jersey</h4>
                        <span>128 Listings starting at 1,999$</span>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 masonry-item">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="acr-country-item">
                            <div class="acr-country-thumb">
                                <img src="assets/img/countries/2.jpg" alt="country">
                            </div>
                            <a routerLink='/listing-map' class="acr-country-content">
                                <h6>Canada</h6>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="acr-country-item">
                            <div class="acr-country-thumb">
                                <img src="assets/img/countries/3.jpg" alt="country">
                            </div>
                            <a routerLink='/listing-map' class="acr-country-content">
                                <h6>Dubai</h6>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="acr-country-item">
                            <div class="acr-country-thumb">
                                <img src="assets/img/countries/4.jpg" alt="country">
                            </div>
                            <a routerLink='/listing-map' class="acr-country-content">
                                <h6>Saudi Arabia</h6>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="acr-country-item">
                            <div class="acr-country-thumb">
                                <img src="assets/img/countries/1.jpg" alt="country">
                            </div>
                            <a routerLink='/listing-map' class="acr-country-content">
                                <h6>California</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Browse By Location End -->
<!-- Listings Start -->
<div class="section section-padding">
    <div class="container">
        <div class="section-title-wrap section-header flex-header">
            <div class="section-title-text">
                <h5 class="custom-primary">Find Your Home</h5>
                <h2 class="title">Recent Listings</h2>
            </div>
            <a routerLink='/listing-map' class="btn-custom">View All</a>
        </div>
        <div class="row">
            <!-- Listing Start -->
            <div class="col-lg-4">
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/2.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Heather Mclayn</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">3</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">2,499</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="col-lg-4">
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge sale"> On Sale</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,300$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">1</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">1,780</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="col-lg-4">
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/2.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Heather Mclayn</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">3</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">2,499</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Listing End -->
        </div>
    </div>
</div>
<!-- Listings End -->
<!-- CTA Start -->
<div class="section cta-banner" style="background-image: url('assets/img/cta/1.jpg')">
    <div class="container">
        <div class="cta cta-3">
            <div class="row">
                <div class="col-lg-6 mb-lg-30">
                    <div class="section-title-wrap">
                        <h5 class="custom-primary">Find a Home</h5>
                        <h2 class="title">Build Your Family</h2>
                        <p class="subtitle">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                        <a routerLink='/listing-map' class="btn-custom">Browse Listings</a>
                    </div>
                </div>
                <div class="col-lg-6 infographics-4">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="acr-infographic-item">
                                <i class="flaticon-house"></i>
                                <div class="acr-infographic-item-body">
                                    <h5>Residential</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="acr-infographic-item">
                                <i class="flaticon-villa"></i>
                                <div class="acr-infographic-item-body">
                                    <h5>Villas</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="acr-infographic-item">
                                <i class="flaticon-apartment"></i>
                                <div class="acr-infographic-item-body">
                                    <h5>Apartments</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="acr-infographic-item">
                                <i class="flaticon-beach-house"></i>
                                <div class="acr-infographic-item-body">
                                    <h5>Beach House</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->
<!-- Agents Start -->
<div class="section agents">
    <div class="container">
        <div class="section-title-wrap section-header flex-header">
            <div class="section-title-text">
                <h5 class="custom-primary">Our Locations</h5>
                <h2 class="title">Meet Our Agents</h2>
            </div>
            <div class="acr-arrows primary-arrows">
                <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
                <i class="slider-next fas fa-arrow-right slick-arrow"></i>
            </div>
        </div>
        <div class="agents-slider">
            <!-- Agent Start -->
            <div class="acr-agent">
                <div class="listing-badge featured">
                    <i class="fas fa-star"></i>
                </div>
                <div class="acr-dots-wrapper acr-agent-thumb">
                    <div class="acr-dots"></div>
                    <a routerLink='/agent-detail'><img src="assets/img/agents/1.jpg" alt="agent"></a>
                </div>
                <div class="acr-agent-body">
                    <h6> <a routerLink='/agent-detail'>Randy Blue</a> </h6>
                    <span>Expert at Company</span>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                </div>
            </div>
            <!-- Agent End -->
            <!-- Agent Start -->
            <div class="acr-agent">
                <div class="acr-dots-wrapper acr-agent-thumb">
                    <div class="acr-dots"></div>
                    <a routerLink='/agent-detail'><img src="assets/img/agents/2.jpg" alt="agent"></a>
                </div>
                <div class="acr-agent-body">
                    <h6> <a routerLink='/agent-detail'>Rinda Flow</a> </h6>
                    <span>Expert at Company</span>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                </div>
            </div>
            <!-- Agent End -->
            <!-- Agent Start -->
            <div class="acr-agent">
                <div class="acr-dots-wrapper acr-agent-thumb">
                    <div class="acr-dots"></div>
                    <a routerLink='/agent-detail'><img src="assets/img/agents/3.jpg" alt="agent"></a>
                </div>
                <div class="acr-agent-body">
                    <h6> <a routerLink='/agent-detail'>Gina Mconihon</a> </h6>
                    <span>Expert at Company</span>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                </div>
            </div>
            <!-- Agent End -->
            <!-- Agent Start -->
            <div class="acr-agent">
                <div class="listing-badge featured">
                    <i class="fas fa-star"></i>
                </div>
                <div class="acr-dots-wrapper acr-agent-thumb">
                    <div class="acr-dots"></div>
                    <a routerLink='/agent-detail'><img src="assets/img/agents/4.jpg" alt="agent"></a>
                </div>
                <div class="acr-agent-body">
                    <h6> <a routerLink='/agent-detail'>Oliver Rasky</a> </h6>
                    <span>Expert at Company</span>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                </div>
            </div>
            <!-- Agent End -->
        </div>
    </div>
</div>
<!-- Agents End -->
<!-- Testimonials Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Testimonials</h5>
            <h2 class="title">What Are People Saying</h2>
        </div>
        <div class="row">
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Perfect service</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/1.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>John Mishlen</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Competitive prices</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/2.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>Moe Sondi</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-12">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Great agents</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/3.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>Mandy Floss</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
        </div>
    </div>
</div>
<!-- Testimonials End -->
<!-- Clients Start -->
<div class="section pt-0">
    <div class="container">
        <div class="clients-slider">
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/1.png" alt="client">
            </div>
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/2.png" alt="client">
            </div>
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/3.png" alt="client">
            </div>
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/4.png" alt="client">
            </div>
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/5.png" alt="client">
            </div>
            <div class="acr-client-item text-center">
                <img src="assets/img/clients/6.png" alt="client">
            </div>
        </div>
    </div>
</div>
<!-- Clients End -->
<app-footer-two></app-footer-two>
