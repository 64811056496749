import {Component, OnInit} from '@angular/core';
import {BrokerageService} from '../../../../../service/brokerage.service';
import {LocalPhoneNumberPipe} from '../../../../../common/pipes/local-phone-number.pipe';

@Component({
  selector: 'app-our-offices-component',
  templateUrl: './our-offices.component.html',
  styleUrls: ['./our-offices.component.css']
})
export class OurOfficesComponent implements OnInit {

  public brokerages;

  public slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '.slider-prev-brokerage',
    nextArrow: '.slider-next-brokerage',
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  constructor(protected brokerageService: BrokerageService) {
  }

  ngOnInit(): void {
    this.getBrokerages();
  }

  async getBrokerages() {

     this.brokerages = await this.brokerageService.getBrokerageList();
  }

}
