<!-- Top Listings Start -->
<div class="section">
  <div class="container top-listings">
    <div class="acr-arrows">
      <i class="slider-prev-top fas fa-arrow-left slick-arrow"></i>
      <i class="slider-next-top fas fa-arrow-right slick-arrow"></i>
    </div>
    <div class="section-title-wrap section-header">
      <h5 class="custom-primary">Trending</h5>
      <h2 class="title">Our Top Listings</h2>
    </div>

    <ngx-slick-carousel class="top-listings-slider"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let listing of listings" class="slide acr-top-listing-item bg-cover dark-overlay bg-center" style="background-image: url({{listing.images[0].property_image_url}})">
        <app-top-listing-component
        [listingInfo]="listing"
        ></app-top-listing-component>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
<!-- Top Listings End -->
