export class GlobalConstants {

  public static listingDefaultValues: ListingListInterface = {
    area: 198682, // Boca Raton
    images: 1, // Get Images
    limit: 20,
    minNumBaths: 1,
    minNumBeds: 1,
    minPrice: 100000,
    maxPrice: 1000000,
    page: 1,
    sortDir: 'DESC',
    orderBy: 'date'
  };

  public static searchableLocation = [
    'Boca Raton, FL',
    'Loxahatchee, FL',
    'Jupiter, FL',
    'Fort Lauderdale, FL',
    'Parkland, FL',
    'Davie, FL',
    'Plantation, FL',
    'Southwest Ranches, FL',
    'Weston, FL',
    'Pembroke Pines, FL',
    'Hollywood, FL',
    'Pompano Beach, FL',
    'Delray Beach, FL',
    'Palm Beach Gardens, FL',
    'Cooper City, FL',
    'Palm Beach, FL',
    'Sunrise, FL',
    'Wellington, FL',
    'West Palm Beach, FL',
    'Schuylkill, FL'
  ];

}
