<div class="section" *ngIf="agent">
  <div class="container">
    <div class="acr-single-agent">
      <div class="acr-single-agent-thumb">
        <img src="{{agent.profileImageLargeUrl}}" alt="{{agent.fullName}}" class="img-thumbnail">
      </div>
      <div class="acr-single-agent-body">
        <h3>Agent Spotlight</h3>
        <p>{{agent.agentBio}}</p>
        <div class="acr-single-agent-meta">
          <h5>{{agent.fullName}}</h5>
          <span>Agent at MV Realty</span>
        </div>
        <div class="acr-rating">
          <i class="fas fa-star active"></i>
          <i class="fas fa-star active"></i>
          <i class="fas fa-star active"></i>
          <i class="fas fa-star active"></i>
          <i class="fas fa-star active"></i>
        </div>
      </div>
    </div>
  </div>
</div>
