import {Injectable} from '@angular/core';

@Injectable()
export class CommonService {

  constructor() {
  }

  /**
   * Get One Listing by ListingId
   */
  mapRequestMVValues(params, agentEmail) {
    return {
      firstName: params.name.split(' ').slice(0, -1).join(' '),
      lastName: params.name.split(' ').slice(0, -1).join(' '),
      email: params.email,
      phone: params.phone,
      addressLine1: params.address,
      state: params.state,
      city: params.city,
      postalCode: params.zip,
      solicitationTxt: params.message,
      leadSourceCd: 'MVREALTY-FORM',
      productId: 5,
      proxyInd: 1,
      agentEmail
    };
  }


}
