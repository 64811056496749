import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
// @ts-ignore
import {ListingListInterface} from '../arces/pages/interface/listing-list.interface';
import {environment} from '../../environments/environment';

@Injectable()
export class RequestService {

  protected MV_API_URL = environment.apiUrl;

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get One Listing by ListingId
   */
  sendEmail(params): Promise<object> {
    let url = '';
    if (this.MV_API_URL[this.MV_API_URL.length - 1] === '/')  {
      url = `${this.MV_API_URL}miscellaneous/handle-web-submission`;
    } else {
      url = `${this.MV_API_URL}/miscellaneous/handle-web-submission`;
    }
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, params).subscribe((response: any) => {
        console.log(response);
        resolve(response);
      });
    });
  }

  /**
   * Get One Listing by ListingId
   */
  sendLead(params): Promise<object> {
    let url = '';
    if (this.MV_API_URL[this.MV_API_URL.length - 1] === '/')  {
      url = `${this.MV_API_URL}leads/capture`;
    } else {
      url = `${this.MV_API_URL}/leads/capture`;
    }
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, params).subscribe((response: any) => {
        console.log(response);
        resolve(response);
      });
    });
  }

}
