<div class="row">
  <div class="col-lg-6">
    <div class="acr-top-listing-body listing">
      <div class="listing-body">
        <h5 class="listing-title"><a routerLink='/listing/{{listingInfo.property_id}}'
                                     title="{{ listingInfo.street }} {{ listingInfo.city }}, {{ listingInfo.state }}"
                                     tabindex="0">{{ listingInfo.street }} {{ listingInfo.city }}
          , {{ listingInfo.state }}</a></h5>
        <div class="listing-author">
          <img src="assets/img/people/2.jpg" alt="author">
          <div class="listing-author-body">
            <p><a href="#" tabindex="0">Listing Date</a></p>
            <span class="listing-date">{{listingInfo.listingDate | date}}</span>
          </div>
<!--          <div class="dropdown options-dropdown">-->
<!--            <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tabindex="0"><i-->
<!--              class="fas fa-ellipsis-v"></i></a>-->
<!--            <div class="dropdown-menu dropdown-menu-right">-->
<!--              <ul>-->
<!--                <li><a href="tel:+123456789" tabindex="0"> <i class="fas fa-phone"></i> Call Agent</a></li>-->
<!--                <li><a href="mailto:+123456789" tabindex="0"> <i class="fas fa-envelope"></i> Send Message</a></li>-->
<!--                <li><a routerLink='/listing/{{listingInfo.property_id}}' tabindex="0"> <i class="fas fa-bookmark"></i>-->
<!--                  Book Tour</a></li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <span class="listing-price">{{(listingInfo.salePrice) ? listingInfo.salePrice : listingInfo.price | currency}}
          <br/>
          <span><b>{{(listingInfo.salePrice) ? listingInfo.salePrice : listingInfo.price | monthlyMortgage | currency}}</b>/month</span>
        </span>
        <p class="listing-text">{{listingInfo.solicitation | titlecase | slice:0:100  }} ...</p>
        <div class="acr-listing-icons">
          <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Beds">
            <i class="flaticon-bedroom"></i>
            <span class="acr-listing-icon-value">{{listingInfo.bedroomsCount}}</span>
          </div>
          <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Bathrooms">
            <i class="flaticon-bathroom"></i>
            <span
              class="acr-listing-icon-value">{{listingInfo.fullBathroomsCount}}{{(listingInfo.halfBathroomsCount) ? '/' + listingInfo.halfBathroomsCount : ''}}</span>
          </div>
          <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Square Feet">
            <i class="flaticon-ruler"></i>
            <span class="acr-listing-icon-value">{{listingInfo.structureAreaSqFt | number}} Sqft.</span>
          </div>
        </div>
        <div class="listing-gallery-wrapper">
          <a routerLink='/listing/{{listingInfo.property_id}}' class="btn-custom btn-sm secondary" tabindex="0">View
            Details</a>
        </div>
      </div>
    </div>
  </div>
</div>
