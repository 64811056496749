<!-- Banner Start -->
<div class="banner banner-2 p-0">
  <div class="banner-slider">
    <div class="banner-item">
      <div class="banner-inner bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/1.jpg')"></div>
      <div class="acr-listing-details">
        <div class="acr-listing-section">
          <div class="acr-listing-nav">
            <a href="#" class="btn-custom secondary">See more details</a>
          </div>
          <div class="acr-listing-section-body">
            <div class="acr-listing-section-price">
              <span>For Sale</span>
              <h3>$852,000</h3>
              <span>Est. Mortgage</span>
              <p>$1,300/mo</p>
            </div>
          </div>
        </div>
        <div class="acr-listing-section">
          <div class="acr-listing-section-body">
            <h4> <a href="#">Iris Watson, Frederick Nebraska 20620</a> </h4>
            <div class="acr-listing-icons">
              <div class="acr-listing-icon">
                <i class="flaticon-bedroom"></i>
                <span>Beds</span>
                <span class="acr-listing-icon-value">3</span>
              </div>
              <div class="acr-listing-icon">
                <i class="flaticon-bathroom"></i>
                <span>Baths</span>
                <span class="acr-listing-icon-value">2</span>
              </div>
              <div class="acr-listing-icon">
                <i class="flaticon-ruler"></i>
                <span>Sqft</span>
                <span class="acr-listing-icon-value">2,499</span>
              </div>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley.
            </p>
          </div>
        </div>
        <div class="acr-listing-section">
          <div class="acr-listing-controls">
            <a href="#" class="acr-listing-control">
              <i class="flaticon-share"></i>
            </a>
            <a href="#" class="acr-listing-control">
              <i class="flaticon-star"></i>
            </a>
            <a href="#" class="acr-schedule-tour acr-listing-control">
              <i class="flaticon-event"></i>
              <span>Schedule a Tour</span>
            </a>
          </div>
          <div class="acr-listing-section-body">
            <div class="acr-listing-meta">
              <div class="row">
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Type</span>
                    <p>House</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>View</span>
                    <p>City View</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Lot Size</span>
                    <p>89 Acres</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Condition</span>
                    <p>Brand New</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-item">
      <div class="banner-inner bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/2.jpg')"></div>
      <div class="acr-listing-details">
        <div class="acr-listing-section">
          <div class="acr-listing-nav">
            <a href="#" class="btn-custom secondary">See more details</a>
          </div>
          <div class="acr-listing-section-body">
            <div class="acr-listing-section-price">
              <span>For Sale</span>
              <h3>$1,200,000</h3>
              <span>Est. Mortgage</span>
              <p>$4,300/mo</p>
            </div>
          </div>
        </div>
        <div class="acr-listing-section">
          <div class="acr-listing-section-body">
            <h4> <a href="#">Theodore Lowe, Azusa New York 39531</a> </h4>
            <div class="acr-listing-icons">
              <div class="acr-listing-icon">
                <i class="flaticon-bedroom"></i>
                <span>Beds</span>
                <span class="acr-listing-icon-value">4</span>
              </div>
              <div class="acr-listing-icon">
                <i class="flaticon-bathroom"></i>
                <span>Baths</span>
                <span class="acr-listing-icon-value">3</span>
              </div>
              <div class="acr-listing-icon">
                <i class="flaticon-ruler"></i>
                <span>Sqft</span>
                <span class="acr-listing-icon-value">3,120</span>
              </div>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley.
            </p>
          </div>
        </div>
        <div class="acr-listing-section">
          <div class="acr-listing-controls">
            <a href="#" class="acr-listing-control">
              <i class="flaticon-share"></i>
            </a>
            <a href="#" class="acr-listing-control">
              <i class="flaticon-star"></i>
            </a>
            <a href="#" class="acr-schedule-tour acr-listing-control">
              <i class="flaticon-event"></i>
              <span>Schedule a Tour</span>
            </a>
          </div>
          <div class="acr-listing-section-body">
            <div class="acr-listing-meta">
              <div class="row">
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Type</span>
                    <p>Duplex</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>View</span>
                    <p>Forest View</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Lot Size</span>
                    <p>180 Acres</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-3 col-sm-3">
                  <div class="acr-listing-meta-item">
                    <span>Condition</span>
                    <p>Brand New</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="acr-arrows">
    <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
    <i class="slider-next fas fa-arrow-right slick-arrow"></i>
  </div>
</div>
<!-- Banner End -->
