import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BrokerageService} from '../../../service/brokerage.service';

@Component({
  selector: 'app-brokerage',
  templateUrl: './brokerage.component.html',
  styleUrls: ['./brokerage.component.css']
})
export class BrokerageComponent implements OnInit {

  brokerages;

  constructor(private route: ActivatedRoute,
              private brokerageService: BrokerageService) {
  }

  async ngOnInit(): Promise<void> {
    this.getBrokerages();
  }


  /**
   * Get Brokerage Info
   */
  async getBrokerages() {
    const brokerages = await this.brokerageService.getBrokerageList();
    this.brokerages = brokerages.map(e => {
      e.image = `assets/img/states/${e.brokerageId}.jpg`;
      return e;
    });
  }

  /**
   * Control No Image Issue
   */
  noImage(e) {
    e.src = 'assets/img/no-image.png';
  }

}
