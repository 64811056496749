<app-header-four></app-header-four>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/contact-subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Contact Us</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->

<!-- Contact Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-lg-30">
                <div class="acr-locations bg-bottom bg-norepeat" style="background-image: url('assets/img/misc/bldg.png')">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="acr-location">
                          <h5>General</h5>
                          <div class="acr-location-address">
                            <a href="tel:+18664843824">(866) 484-3824</a>
                          </div>
                        </div>
                      </div>
                        <div class="col-sm-6">
                            <div class="acr-location">
                                <h5>Refinancing or Mortgage Questions</h5>
                                <div class="acr-location-address">
                                    <a href="tel:+15614869355">(561) 486-9355</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="acr-location">
                                <h5>Employment Opportunities</h5>
                                <div class="acr-location-address">
                                    <a href="tel:+18664843824">(866) 484-3824</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="acr-location">
                                <h5>Rescissions</h5>
                                <div class="acr-location-address">
                                    <a href="tel:+15614869355">(561) 486-9355</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="acr-location">
                                <h5>Payment Information and Wire Confirmation</h5>
                                <div class="acr-location-address">
                                    <a href="tel:+15614142505">(561) 414-2505</a>
                                </div>
                            </div>
                        </div>
                      <div class="col-sm-6">
                        <div class="acr-location">
                          <h5>I want to sell my home</h5>
                          <div class="acr-location-address">
                            <a href="tel:+18668221448">(866) 822-1448</a>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 contact-container">
                <div class="section-title-wrap section-header">
                    <h5 class="custom-primary">Contact Us</h5>
                    <h2 class="title">Do You Have Any Questions?</h2>
                </div>
                <div class="comment-form" *ngIf="!submitted">
                    <form [formGroup]="contactForm" method="post">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>Full Name</label>
                                <input type="text" class="form-control" placeholder="Full Name" name="fname" value="" formControlName="fullName">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Subject</label>
                                <input type="text" class="form-control" placeholder="Subject" name="subject" value="" formControlName="subject">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Phone Number</label>
                                <input type="text" class="form-control" placeholder="Phone Number" name="phone" value="" formControlName="phone">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Email Address</label>
                                <input type="email" class="form-control" placeholder="Email Address" name="email" value="" formControlName="email">
                            </div>
                            <div class="col-md-12 form-group">
                                <label>Your Message</label>
                                <textarea class="form-control" placeholder="Type your message..." name="comment" rows="7" formControlName="messageTxt"></textarea>
                            </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <!-- <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha> -->
                          <re-captcha id="recaptcha" name="recaptcha" formControlName="recaptcha" (resolved)="recaptchaResolved($event)" (errored)="recaptchaErrored()"></re-captcha>
                          <div *ngIf="contactForm.controls['recaptcha'].invalid && (contactForm.controls['recaptcha'].dirty || contactForm.controls['recaptcha'].touched)" class="invalid-feedback">
                             <div *ngIf="contactForm.controls['recaptcha'].errors['required']">This field is required.</div>
                          </div>
                        </div>
                        <button type="submit" class="btn-custom primary" name="button" (click)="submitContactForm()">Send Message</button>
                    </form>
                </div>
              <div *ngIf="submitted">
                <h5>Thank you for your interest! We'll reach out to you shortly!</h5>
              </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact End -->
<app-footer></app-footer>
