import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'monthlyMortgage'
})

export class MortgagePipe implements PipeTransform {
  transform(value: number): number {
    const p = value;
    const i = 3.5 / 100 / 12;
    const n = 30 * 12;
    return p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
  }
}
