<div class="listing-thumbnail">
  <a routerLink='/listing/{{listingInfo.property_id}}'>
    <img src="{{listingInfo.images[0].property_image_url}}" alt="listing" (error)="noImage($event)"></a>
  <div class="listing-badges">
    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
  </div>
</div>
<div class="listing-body">
  <div class="listing-author">
    <img src="assets/img/people/2.jpg" alt="author">
    <div class="listing-author-body">
      <p><a href="#">Heather Mclayn</a></p>
      <span class="listing-date">{{listingInfo.listingDate | date }}</span>
    </div>
    <div class="dropdown options-dropdown">
      <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
        class="fas fa-ellipsis-v"></i></a>
      <div class="dropdown-menu dropdown-menu-right">
        <ul>
          <li><a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a></li>
          <li><a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a></li>
          <li><a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a></li>
        </ul>
      </div>
    </div>
  </div>
  <h6 class="listing-title">
    <a routerLink='/listing/{{listingInfo.property_id}}'
       title="{{ listingInfo.street }} {{ listingInfo.city }}, {{ listingInfo.state }}">{{ listingInfo.street }}
      ({{ listingInfo.city }})</a></h6>
  <span class="listing-price">{{listingInfo.price | currency }}</span>
  <p class="listing-text">{{listingInfo.solicitation | titlecase | slice:0:100  }} ...</p>
  <div class="acr-listing-icons">
    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
      <i class="flaticon-bedroom"></i>
      <span class="acr-listing-icon-value">{{listingInfo.bedroomsCount}}</span>
    </div>
    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
      <i class="flaticon-bathroom"></i>
      <span class="acr-listing-icon-value">{{listingInfo.fullBathroomsCount}}</span>
    </div>
    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
      <i class="flaticon-ruler"></i>
      <span class="acr-listing-icon-value">{{listingInfo.structureAreaSqFt | number}}</span>
    </div>
  </div>
  <div class="listing-gallery-wrapper">
    <a routerLink='/listing/{{listingInfo.property_id}}' class="btn-custom btn-sm secondary">View Details</a>
<!--    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i>-->
<!--    </a>-->
  </div>
</div>
