<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Pricing</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Pricing Start -->
<div class="section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="acr-pricing">
                    <div class="acr-pricing-header">
                        <h4>Free</h4>
                        <p>Package Includes</p>
                    </div>
                    <div class="acr-pricing-body">
                        <div class="acr-pricing-feature">
                            <h6>Listing Submission: <i data-toggle="tooltip" title="Allowed Listings Per Month" class="fas fa-question-circle"></i></h6>
                            <span>Up to 2 Listings</span>
                        </div>
                        <div class="acr-pricing-feature">
                            <h6>Agent Consultation: <i data-toggle="tooltip" title="Monthly Agent Reports" class="fas fa-question-circle"></i></h6>
                            <span>Not Available</span>
                        </div>
                    </div>
                    <div class="acr-pricing-footer">
                        <a href="#" class="btn-custom-2 grey">Get Package</a>
                        <h4 class="custom-primary">0.00$ <span>/mo</span> </h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="acr-pricing hot-plan">
                    <div class="acr-pricing-header">
                        <h4>Basic</h4>
                        <p>Package Includes</p>
                    </div>
                    <div class="acr-pricing-body">
                        <div class="acr-pricing-feature">
                            <h6>Listing Submission: <i data-toggle="tooltip" title="Allowed Listings Per Month" class="fas fa-question-circle"></i> </h6>
                            <span>Up to 5 Listings</span>
                        </div>
                        <div class="acr-pricing-feature">
                            <h6>Agent Consultation: <i data-toggle="tooltip" title="Monthly Agent Reports" class="fas fa-question-circle"></i></h6>
                            <span>Not Available</span>
                        </div>
                    </div>
                    <div class="acr-pricing-footer">
                        <a href="#" class="btn-custom-2 light">Get Package</a>
                        <h4>12.99$ <span>/mo</span></h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="acr-pricing">
                    <div class="acr-pricing-header">
                        <h4>Commercial</h4>
                        <p>Package Includes</p>
                    </div>
                    <div class="acr-pricing-body">
                        <div class="acr-pricing-feature">
                            <h6>Listing Submission: <i data-toggle="tooltip" title="Allowed Listings Per Month" class="fas fa-question-circle"></i></h6>
                            <span>Up to 20 Listings</span>
                        </div>
                        <div class="acr-pricing-feature">
                            <h6>Agent Consultation: <i data-toggle="tooltip" title="Monthly Agent Reports" class="fas fa-question-circle"></i></h6>
                            <span>Monthly Consultation</span>
                        </div>
                    </div>
                    <div class="acr-pricing-footer">
                        <a href="#" class="btn-custom-2 grey">Get Package</a>
                        <h4 class="custom-primary">39.99$ <span>/mo</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Pricing End -->
<!-- FAQs Start -->
<div class="section pt-0">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">FAQ</h5>
            <h2 class="title">FAQ Pricing</h2>
        </div>
        <div class="accordion with-gap" id="faqAccordions1">
            <div class="card">
                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                    How can I start with buying a home?
                </div>
                <div id="faqOne" class="collapse show" data-parent="#faqAccordions1">
                    <div class="card-body">
                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                            tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </p>
                        <ul class="acr-list mb-0">
                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                            <li> There are many variations of passages of Lorem Ipsum </li>
                            <li> Internet tend to repeat predefined chunks as necessary </li>
                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                    Is the service free?
                </div>
                <div id="faqTwo" class="collapse" data-parent="#faqAccordions1">
                    <div class="card-body">
                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                            tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </p>
                        <ul class="acr-list mb-0">
                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                            <li> There are many variations of passages of Lorem Ipsum </li>
                            <li> Internet tend to repeat predefined chunks as necessary </li>
                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                    How many agents can I have at once?
                </div>
                <div id="faqThree" class="collapse" data-parent="#faqAccordions1">
                    <div class="card-body">
                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                            tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </p>
                        <ul class="acr-list mb-0">
                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                            <li> There are many variations of passages of Lorem Ipsum </li>
                            <li> Internet tend to repeat predefined chunks as necessary </li>
                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FAQs End -->
<app-footer></app-footer>