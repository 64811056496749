<!-- Listings Slider Start -->
<div class="section listings light-bg">
  <div class="container">

    <div class="section-title-wrap section-header flex-header">
      <div class="section-title-text">
        <h5 class="custom-primary">Find Your Home</h5>
        <h2 class="title">Recent Listings</h2>
      </div>
      <div class="acr-arrows primary-arrows">
        <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
        <i class="slider-next fas fa-arrow-right slick-arrow"></i>
      </div>
    </div>

    <ngx-slick-carousel class="listings-slider"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let listing of listings" class="slide listing">
        <app-listing-component
        [listingInfo]="listing"
        ></app-listing-component>
      </div>
    </ngx-slick-carousel>

  </div>

</div>
<!-- Listings Slider End -->
