export interface TextTestimonial {
  title: string;
  author: string;
  stars: number;
  starArray?: number[];
  text: string;
  type: 'text';
  location: string;
}

export interface VideoTestimonial {
  title: string;
  location: string;
  info: string;
  disclaimer: string;
  video: string;
  thumbnail: string;
  author: string;
  type: 'video';
}

export type Testimonial = VideoTestimonial | TextTestimonial;

export const testimonials: Testimonial[] = [
  {
    author: "Mark and Silvia",
    location: "Florida",
    info: "Homeowner Benefit Program ® Clients Who Sold Their Home with MV Realty",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/mark_and_silvia.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/mark_silvia.jpg",
    title: "Well worth it"
  },
  {
    title: "So professional",
    author: "Philip A.",
    stars: 5,
    type: "text",
    text: "So professional. Thorough. Friendly & knowledgeable. Wow! Recommended!!!",
    location: "USA"
  },
  {
    author: "Orlando S.",
    location: "Missouri",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/orlando_soto.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/orlando.jpg",
    title: "Very polite... professional... and very knowledgeable."
  },
  {
    author: "Glenda R.",
    location: "South Carolina",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/glenda_russell.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/glenda.jpg",
    title: "If 5 was the highest, I'd give it a 15!"
  },
  {
    author: "Ada W.",
    location: "California",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/ada_wadner.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/ada.jpg",
    title: "Comforting to know that you have someone you can reach out to"
  },
  {
    title: "Best experience ever",
    author: "Bryan P.",
    stars: 5,
    type: "text",
    text: "So far the best experience ever from a real estate company. My realtor checks up on us every month sometimes every 2 weeks or so. No pressure to sell my home. Building the relationship is what they are about. Great company.",
    location: "USA"
  },
  {
    title: "Great marketing concept",
    author: "Michael K.",
    stars: 5,
    type: "text",
    text: "Great marketing concept , pro active. I'm sure when were ready to sell they will provide pro active marketing to achieve that goal. I recommend talking with these people.",
    location: "USA"
  },
  {
    author: "Kerianne",
    location: "Florida",
    info: "Homeowner Benefit Program ® Client Who Sold Her Home with MV Realty",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/Keri+(HBA_Sales_Client).mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/keri.jpg",
    title: "Came at the perfect time"
  },
  {
    author: "Catlin M.",
    location: "Oregon",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/catlin_moser.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/catlin.jpg",
    title: "Recommended to quite a few family and friends"
  },
  {
    author: "Michael M.",
    location: "Arizona",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/michael_moncur.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/michael.jpg",
    title: "Great opportunity"
  },
  {
    author: "Carolyn J.",
    location: "Texas",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/carolyn_johnson.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/carolyn.jpg",
    title: "A lucky day for me"
  },
  {
    author: "Cortney",
    location: "Virginia",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/cortney.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/cortney.jpg",
    title: "Recommend to family and friends"
  },
  {
    author: "Elizabeth P.",
    location: "Alabama",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/elizabeth_phelps.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/elizabeth.jpg",
    title: "Any questions we had they answered"
  },
  {
    author: "Leveran",
    location: "Maryland",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/leveran_banks.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/leveran.jpg",
    title: "Valerie... is truly amazing"
  },
  {
    title: "Amazing partner",
    author: "Melissa G.",
    stars: 5,
    type: "text",
    text: "MV has been an amazing partner when looking for real estate in such a competitive market. They keep a close eye on listings and notify me of properties that may be of interest. Most times this happens prior to the property listing going public. I will use MV for all of my real estate transactions for the foreseeable future.",
    location: "USA"
  },
  {
    author: "Recennah B.",
    location: "Alabama",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/recennah_braxton.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/recennah.jpg",
    title: "Very positive experience"
  },
  {
    author: "Millicent",
    location: "Nevada",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/millicent.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/millicent.jpg",
    title: "Agent was excellent"
  },
  {
    author: "Kevin N.",
    location: "Missouri",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/kevin_newcomb.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/kevin.jpg",
    title: "Recommend to anyone willing to listen"
  },
  {
    author: "Steve C.",
    location: "California",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/steve_candelaria.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/steve.jpg",
    title: "Answered all my questions"
  },
  {
    author: "Chi S.",
    location: "Georgia",
    info: "Homeowner Benefit Program ® Client",
    disclaimer: "*This client gave a voluntary testimonial and was paid to do a video",
    type: "video",
    video: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Videos/chi_smith.mp4",
    thumbnail: "https://f002.backblazeb2.com/file/mvrealty-misc/Testimonial+Thumbnails/chi.jpg",
    title: "My best friends now"
  },
  {
    title: "Wonderful new and innovative",
    author: "Beth V",
    stars: 5,
    type: "text",
    text: "Wonderful new and innovative ways to secure your Realtor® in advance, should anything come up, you are all set to get your home sold , by Them.!! Smart thinking, thinking out of the Box!! Think ahead, cross this one off the list!",
    location: "USA"
  },

]