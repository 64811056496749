import {Component, OnInit} from '@angular/core';
import {AgentService} from '../../../service/agent.service';
import {groupBy, mapValues, omit} from 'lodash';
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {

  agents;
  loadedAgents;
  limitPerPage = 30;
  currentPage = 1;
  numberPages = 1;
  initStatus = true;
  groupedBrokerages;

  constructor(protected agentService: AgentService,
              private viewportScroller: ViewportScroller) {
  }

  ngOnInit(): void {
    this.getAgents();
  }

  async getAgents() {
    const excludeAgentsIds = [25, 132, 135]; // Unassigned 25 - Test 132
    const agents = await this.agentService.getAgentListByBrokerage();
    this.loadedAgents = agents.map((agent) => {
      if (!agent.agentBio || (agent.agentBio).trim() === '') {
        agent.agentBio = `${agent.fullName} is a leading property specialist with MV Realty, specializing in buying, selling, and marketing residential and commercial real estate.`;
      }
      if (!agent.profileImageLargeUrl || agent.profileImageLargeUrl === '') {
        agent.profileImageLargeUrl = 'assets/img/no-image.png';
      }
      return agent;
    }).filter((agent) => !excludeAgentsIds.includes(agent.agentId));

    this.groupedBrokerages = groupBy(this.loadedAgents, 'stateName');

    // Disabled Pagination
    // const totalAgents = agents.length;
    // this.numberPages = Math.trunc((totalAgents / this.limitPerPage)) + 1;
    // this.reloadAgentList(this.currentPage);
  }

  /**
   * Pagination Action
   */
  async reloadAgentList(newPage) {
    const element = document.querySelector('#agents');
    if (element && !this.initStatus) {
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    this.currentPage = newPage;
    this.initStatus = false;
    const nextPoint = (this.currentPage - 1) * this.limitPerPage;
    this.agents = this.loadedAgents.slice(nextPoint, (this.limitPerPage + nextPoint));
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
