import {Component, OnInit} from '@angular/core';
import {AgentService} from '../../../../../service/agent.service';

@Component({
  selector: 'app-meet-our-agents-component',
  templateUrl: './meet-our-agents.component.html',
  styleUrls: ['./meet-our-agents.component.css']
})
export class MeetOurAgentsComponent implements OnInit {

  public agents;

  public slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '.slider-prev-agents',
    nextArrow: '.slider-next-agents',
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  constructor(protected agentService: AgentService) {
  }

  ngOnInit(): void {
    this.getAgents();
  }

  async getAgents() {
    const excludeAgentsIds = [25, 132]; // Unassigned 25 - Test 132
    const agents = await this.agentService.getAgentListByBrokerage();
    this.agents = agents.map((agent) => {
      if (!agent.agentBio || (agent.agentBio).trim() === '') {
        agent.agentBio = `${agent.fullName} is a leading property specialist with MV Realty, specializing in buying, selling, and marketing residential and commercial real estate.`;
      }
      if (!agent.profileImageLargeUrl || agent.profileImageLargeUrl === '') {
        agent.profileImageLargeUrl = 'assets/img/no-image.png';
      }
      return agent;
    }).filter((agent) => !excludeAgentsIds.includes(agent.agentId));
  }

}
