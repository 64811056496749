<app-header-four></app-header-four>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/banner/main.jpg')">
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">Team</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Team</li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<!-- Subheader End -->
<div class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <!-- Agent Start -->
          <div class="col-lg-4" *ngFor="let brokerage of brokerages">
            <div class="acr-agent">
              <app-brokerage-single [brokerage]="brokerage"></app-brokerage-single>
            </div>
          </div>
          <!-- Agent End -->
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
