<app-header-four></app-header-four>
<!-- Banner Start -->
<div class="banner banner-1 banner-3 dark-overlay bg-cover bg-center" style="background-image: url('assets/img/banner/3.jpg')">
    <div class="container">
        <div class="banner-item">
            <div class="banner-inner">
                <div class="banner-text">
                    <h1 class="title text-white">Find Your Ideal Home Today</h1>
                    <p class="subtitle text-white">Thousands of people have their flats up for grabs. Don't miss your chance to grab your own house today.</p>
                </div>
                <div class="acr-filter-form">
                    <form method="post">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Location: </label>
                                    <select class="acr-select2" name="location">
                                        <option value="" label="Location"></option>
                                        <option value="*">Any Location</option>
                                        <option value="California, CA">California, CA</option>
                                        <option value="Lawndale, CA">Lawndale, CA</option>
                                        <option value="Stroudsburg, PA">Stroudsburg, PA</option>
                                        <option selected value="West Roxbury, MA">West Roxbury, MA</option>
                                        <option value="Ponte Vedra Beach, FL">Ponte Vedra Beach, FL</option>
                                        <option value="Fort Worth, TX">Fort Worth, TX</option>
                                        <option value="Willingboro, NJ">Willingboro, NJ</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Status: </label>
                                    <select class="acr-select2" name="status">
                                        <option value="" label="Status"></option>
                                        <option selected value="*">Any Status</option>
                                        <option value="For Rent">For Rent</option>
                                        <option value="Featured">Featured</option>
                                        <option value="For Sale">For Sale</option>
                                        <option value="Leased">Leased</option>
                                        <option value="New Addition">New Addition</option>
                                        <option value="Sold">Sold</option>
                                        <option value="Rental">Rental</option>
                                        <option value="Reduced">Reduced</option>
                                        <option value="Special Offer">Special Offer</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="form-group acr-custom-select">
                                    <label>Price Range: </label>
                                    <select class="acr-select2" name="price">
                                        <option value="" label="Price Range"></option>
                                        <option selected value="*">Any Range</option>
                                        <option value="$60k - $80k">$60k - $80k</option>
                                        <option value="$80k - $100k">$80k - $100k</option>
                                        <option value="$100k - $120k">$100k - $120k</option>
                                        <option value="$120k - $140k">$120k - $140k</option>
                                        <option value="$140k - $160k">$140k - $160k</option>
                                        <option value="$160k - $180k">$160k - $180k</option>
                                        <option value="$180k - $200k">$180k - $200k</option>
                                    </select>
                                </div>
                            </div>
                            <div class="submit-btn col-lg-2 col-md-6">
                                <div class="form-group">
                                    <button type="submit" class="btn-custom secondary btn-block" name="button">Search listings</button>
                                </div>
                            </div>
                        </div>
                        <div class="advanced-search">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="acr-custom-select form-group">
                                        <label>Beds: </label>
                                        <select class="acr-select2" name="beds">
                                            <option value="" label="Beds"></option>
                                            <option selected value="*">Any amount</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5+">5+</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="acr-custom-select form-group">
                                        <label>Bathrooms: </label>
                                        <select class="acr-select2" name="baths">
                                            <option value="" label="Bathrooms"></option>
                                            <option selected value="*">Any amount</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5+">5+</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="acr-custom-select form-group">
                                        <label>Type: </label>
                                        <select class="acr-select2" name="type">
                                            <option value="" label="Type"></option>
                                            <option selected value="*">Any Type</option>
                                            <option value="House">House</option>
                                            <option value="Apartment">Apartment</option>
                                            <option value="Condo">Condo</option>
                                            <option value="Townhome">Townhome</option>
                                            <option value="Villa">Villa</option>
                                            <option value="Duplex">Duplex</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="acr-custom-select form-group">
                                        <label>Diameter: </label>
                                        <select class="acr-select2" name="diameter">
                                            <option value="" label="Diameter"></option>
                                            <option selected value="*">Any Range</option>
                                            <option value="Within 2 miles">Within 2 miles</option>
                                            <option value="Within 4 miles">Within 4 miles</option>
                                            <option value="Within 6 miles">Within 6 miles</option>
                                            <option value="Within 8 miles">Within 8 miles</option>
                                            <option value="Within 10 miles">Within 10 miles</option>
                                            <option value="Within 12 miles">Within 12 miles</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="advanced-search-trigger semi-circle">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Banner End -->
<!-- Categories Start -->
<div class="section section-padding">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Categories</h5>
            <h2 class="title">Browse By Category</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-company"></i>
                        <a href="#"><img src="assets/img/categories/1.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Commercial</a> </h5>
                            <span>90 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-house"></i>
                        <a href="#"><img src="assets/img/categories/2.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Residential</a> </h5>
                            <span>433 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-villa"></i>
                        <a href="#"><img src="assets/img/categories/3.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Villas</a> </h5>
                            <span>221 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-apartment"></i>
                        <a href="#"><img src="assets/img/categories/4.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Apartments</a> </h5>
                            <span>185 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-beach-house"></i>
                        <a href="#"><img src="assets/img/categories/5.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Beach House</a> </h5>
                            <span>230 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-category">
                    <div class="acr-category-thumb">
                        <i class="flaticon-garage"></i>
                        <a href="#"><img src="assets/img/categories/6.jpg" alt="category"></a>
                        <div class="acr-category-body">
                            <h5> <a href="#">Duplex</a> </h5>
                            <span>365 Listings</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Categories End -->
<!-- Buyer/Seller Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="cta cta-2 item1">
                    <i class="flaticon-sales-agent"></i>
                    <div class="cta-body">
                        <h4>Buying a Home?</h4>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        </p>
                        <a routerLink='/listing-map' class="btn-link">Find Out More <i class="fas fa-arrow-right"></i> </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="cta cta-2 item2">
                    <i class="flaticon-sold"></i>
                    <div class="cta-body">
                        <h4>Selling a Home?</h4>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                        </p>
                        <a routerLink='/pricing' class="btn-link">Find Out More <i class="fas fa-arrow-right"></i> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Buyer/Seller Start -->
<!-- CTA Start -->
<div class="acr-footer footer-2">
    <div class="footer-top">
        <div class="container">
            <div class="row footer-btn-wrapper">
                <div class="col-lg-7 footer-widget">
                    <h4>Download Our App</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </p>
                </div>
                <div class="col-lg-5 col-md-12 footer-widget">
                    <div class="footer-btn">
                        <a href="#" class="btn-custom-2 grey"> <i class="flaticon-playstore"></i>Google Play</a>
                        <a href="#" class="btn-custom-2 grey"> <i class="flaticon-apple"></i>App Store</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->
<!-- Listings Start -->
<div class="section">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Find Your Home</h5>
            <h2 class="title">Recent Listings</h2>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="listing listing-list">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/8.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/2.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Heather Mclayn</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">3</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="" data-original-title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">2,499</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="" class="listing-gallery" data-original-title="Gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge sale"> On Sale</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,300$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">1</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">1,780</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge sale"> On Sale</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,300$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">1</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">1,780</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
                <div class="listing">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge sale"> On Sale</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <div class="listing-author">
                            <img src="assets/img/people/1.jpg" alt="author">
                            <div class="listing-author-body">
                                <p> <a href="#">Randy Blue</a> </p>
                                <span class="listing-date">March 3, 2020</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                        <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                        <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,300$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div class="acr-listing-icons">
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                <i class="flaticon-bedroom"></i>
                                <span class="acr-listing-icon-value">2</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                <i class="flaticon-bathroom"></i>
                                <span class="acr-listing-icon-value">1</span>
                            </div>
                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                <i class="flaticon-ruler"></i>
                                <span class="acr-listing-icon-value">1,780</span>
                            </div>
                        </div>
                        <div class="listing-gallery-wrapper">
                            <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                            <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Listings End -->
<!-- Blog Posts Start -->
<div class="section section-padding light-bg">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Latest News</h5>
            <h2 class="title">From Our Blog</h2>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <article class="post">
                    <div class="post-thumbnail">
                        <a routerLink='/blog-single'><img src="assets/img/blog/1.jpg" alt="blog post"></a>
                    </div>
                    <div class="post-body">
                        <h5 class="post-title"> <a routerLink='/blog-single'>What agencies are looking for in an agent</a> </h5>
                        <p class="post-text">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                        <div class="post-controls">
                            <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                            <div class="post-comment-authors">
                                <span>Comments by: </span>
                                <div class="post-comment-authors-img">
                                    <a href="#"> <img src="assets/img/people/3.jpg" alt="comment author"> </a>
                                    <a href="#"> <img src="assets/img/people/4.jpg" alt="comment author"> </a>
                                    <a href="#"> +3 </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <div class="col-lg-6">
                <article class="post post-list">
                    <div class="post-thumbnail">
                        <a routerLink='/blog-single'><img src="assets/img/blog/2.jpg" alt="blog post"></a>
                    </div>
                    <div class="post-body">
                        <h5 class="post-title"> <a routerLink='/blog-single'>Changing the way we sell and buy real estate</a> </h5>
                        <p class="post-text">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                    </div>
                </article>
                <article class="post post-list">
                    <div class="post-thumbnail">
                        <a routerLink='/blog-single'><img src="assets/img/blog/3.jpg" alt="blog post"></a>
                    </div>
                    <div class="post-body">
                        <h5 class="post-title"> <a routerLink='/blog-single'>Families, its the best time to start buying</a> </h5>
                        <p class="post-text">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                        </p>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>
<!-- Blog Posts End -->
<!-- FAQs Start -->
<div class="section">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">FAQ</h5>
            <h2 class="title">FAQ Pricing</h2>
        </div>
        <div class="row">
            <div class="col-lg-8 mb-lg-30">
                <div class="accordion with-gap" id="faqAccordions1">
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                            How can I start with buying a home?
                        </div>
                        <div id="faqOne" class="collapse show" data-parent="#faqAccordions1">
                            <div class="card-body">
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                    tempor,
                                    sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                </p>
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                </p>
                                <ul class="acr-list mb-0">
                                    <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                    <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                    <li> There are many variations of passages of Lorem Ipsum </li>
                                    <li> Internet tend to repeat predefined chunks as necessary </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                            Is the service free?
                        </div>
                        <div id="faqTwo" class="collapse" data-parent="#faqAccordions1">
                            <div class="card-body">
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                    tempor,
                                    sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                </p>
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                </p>
                                <ul class="acr-list mb-0">
                                    <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                    <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                    <li> There are many variations of passages of Lorem Ipsum </li>
                                    <li> Internet tend to repeat predefined chunks as necessary </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                            How many agents can I have at once?
                        </div>
                        <div id="faqThree" class="collapse" data-parent="#faqAccordions1">
                            <div class="card-body">
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                    tempor,
                                    sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                </p>
                                <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                                </p>
                                <ul class="acr-list mb-0">
                                    <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                    <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                    <li> There are many variations of passages of Lorem Ipsum </li>
                                    <li> Internet tend to repeat predefined chunks as necessary </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 infographics-5">
                <div class="acr-infographic-item">
                    <i class="flaticon-telephone"></i>
                    <div class="acr-infographic-item-body">
                        <h5>Reach Out</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <a href="tel:+123456789" class="btn-custom secondary btn-sm">Call Us</a>
                    </div>
                </div>
                <div class="acr-infographic-item">
                    <i class="flaticon-location"></i>
                    <div class="acr-infographic-item-body">
                        <h5>Find Us</h5>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <a href="#" class="btn-custom secondary btn-sm">Get Directions</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- FAQs End -->
<app-footer-three></app-footer-three>
