<app-header-four></app-header-four>
<!-- Subheader Start -->
<div class="header-1"></div>
<div
  class="subheader bg-cover bg-center dark-overlay"
  style="background-image: url('assets/img/banner/main.jpg')"
>
  <div class="container">
    <div class="subheader-inner">
      <h1 class="text-white">Testimonials</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/"> <i class="fas fa-home"></i> </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Testimonials
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div
  class="section section-padding {{ backgroundColor }}"
  id="what-are-people-saying"
>
  <div class="container">
    <div class="section-title-wrap section-header">
      <h5 class="custom-primary">Testimonials</h5>
      <h2 class="title">What Are People Saying</h2>
      <br />
      <div class="brick-container">

          <div *ngFor="let t of testimonials" class="brick">

            <!-- VIDEO -->
            <div *ngIf="t.type == 'video'" class="video-testimonial">

              <div class="acr-testimonial">
                <div class="acr-testimonial-body">
                  <h5>{{ t.title }}</h5>
                  <div class="acr-rating-wrapper">
                    <div class="acr-rating">
                      <i
                        class="fas fa-star active"
                        *ngFor="let number of t.starArray"
                      ></i>
                    </div>
                  </div>
                  <div class="image-container" (click)="launchPlayer(videoPlayer, t)">
                    <img
                      src="{{ t.thumbnail }}"
                      alt="{{ t.title }}"
                      class="video-testimonial-image"
                    />
                    <div class="image-hover">
                      <i class="fas fa-play custom-primary playbutton"></i>
                    </div>
                  </div>
                  <p class="disclaimer">{{ t.disclaimer }}</p>
                  <p class="text-secondary">{{ t.info }}</p>
                </div>
                <div class="acr-testimonial-author">
                  <div class="acr-testimonial-author-inner">
                    <h6>{{ t.author }} - {{ t.location }}</h6>
                  </div>
                </div>
              </div>
            </div>

            <!-- TEXT -->
            <div *ngIf="t.type == 'text'">
              <div class="acr-testimonial">
                <div class="acr-testimonial-body">
                  <h5>{{ t.title }}</h5>
                  <div class="acr-rating-wrapper">
                    <div class="acr-rating">
                      <i
                        class="fas fa-star active"
                        *ngFor="let number of t.starArray"
                      ></i>
                    </div>
                  </div>
                  <p>{{ t.text }}</p>
                </div>
                <div class="acr-testimonial-author">
                  <div class="acr-testimonial-author-inner">
                    <h6>{{ t.author }} - {{ t.location }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <br />
    </div>
  </div>
  <ng-template #videoPlayer let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ videoContent.title }}
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="video-container">
        <video
          controls="controls"
          width="100%"
          height="100%"
          class="testimonial-video"
          autoplay="true"
          poster="{{ videoContent.thumbnail }}"
        >
          <source [src]="videoContent.video" />
        </video>
      </div>
    </div>
  </ng-template>
  <app-footer></app-footer>
</div>
